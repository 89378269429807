<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-12 col-md-9 px-2'">
    <div class="d-flex flex-row"
         [ngClass]="{'cursor-pointer': !isReadOnly}">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

      </div>
    </div>
  </div>
  <div [ngClass]="fullWidth ?  'col-12' : 'col-12 col-md-9'" class="position-relative">
    <!--Icon-->
    <div class="input-group"
         [style.width]="width">
      <div class="input-group-text">

        <i class="ki-outline ki-calendar text-primary fs-1"></i>
      </div>

      <!--Editable-->
      <input *ngIf="!isReadOnly"
             #date
             type="text"
             class="form-control rounded-end"
             mwlFlatpickr
             [options]=options
             (flatpickrOpen)="onOpen($event)"
             (flatpickrClose)="onClose($event)"
             [ngClass]="{'is-invalid ': control.invalid && !control.pristine, 'text-gray-600': isReadOnly}"
             [formControl]="control"
             placeholder="Choose a date">
      <span class="position-absolute translate-middle-y top-50 end-0 me-3">

        <span *ngIf="!isReadOnly && control.value != null"
              (click)="$event.stopPropagation(); clearInput();"
              title="Clear Input">
          <i class="ki-outline ki-cross-circle text-danger hover-scale cursor-pointer fs-2x"></i>
        </span>
      </span>

      <!--Read Only-->
      <input *ngIf="isReadOnly"
             readonly
             type="text"
             [ngModel]="control.value | amDateFormat: 'yyyy-MM-DD H:i'"
             placeholder="yyyy-mm-dd"
             class="form-control text-gray-600">
    </div>
    <div *ngIf="control.invalid && !control.pristine && !isReadOnly"
         class="fv-plugins-message-container">
      <div *ngIf="control.errors"
           class="fv-help-block">
        {{getErrorMessages()}}
      </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

