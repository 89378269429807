import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buttonColour'
})
export class ButtonColourPipe implements PipeTransform {

  transform(value: boolean) {

    switch (value) {
      case true: {
        return 'btn-light-success';
      }
      case false: {
        return 'btn-light-danger';
      }
      default: {
        return 'btn-light-primary';
      }
    } 
  }
}
