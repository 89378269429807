import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[autoExpandingTextarea]'
})
export class AutoExpandingTextarea implements OnInit, OnDestroy {

  @Output('autoExpandingTextareaHeightChanged')
  heightChanged = new EventEmitter();

  private destroyed = new Subject<boolean>();
  
  constructor(private el: ElementRef<HTMLTextAreaElement>,
    private renderer2: Renderer2) {
  }

  ngOnInit() {
    this.setInitialStyle();
    this.setupEventListeners();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private setInitialStyle() {
    this.renderer2.setStyle(this.el.nativeElement, 'resize', 'none');
    this.renderer2.setStyle(this.el.nativeElement, 'overflow', 'hidden');
  }

  private setupEventListeners() {
    merge(
      fromEvent(this.el.nativeElement, 'keyup'),
      fromEvent(this.el.nativeElement, 'cut'),
      fromEvent(this.el.nativeElement, 'paste')
    ).pipe(
      tap(() => this.renderer2.setStyle(this.el.nativeElement, 'height', `auto`)),
      takeUntil(this.destroyed),
      map(() => this.el.nativeElement.scrollHeight)
    ).subscribe(scrollHeight => {
      this.renderer2.setStyle(this.el.nativeElement, 'height', `${scrollHeight}px`);
      this.heightChanged.emit();
    });
  }
}
