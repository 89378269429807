import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Editor } from '@shared/editors/editor';

@Component({
  selector: 'fit-select-editor',
  templateUrl: './select.editor.html',
  styleUrls: ['./editor.scss', './select.editor.scss']
})
export class SelectEditor extends Editor {

  @Input() width = '100%';

  @Input() defaultMargins = true;

  @Input() busyLoading = false;

  @Input() options = new Array<any>();

  @Input() addNullOption = false;

  @Input() value: string;

  @Input() display: string;

  @Output() selectionChanged = new EventEmitter<any>();

  @Input() canClear = true;

  handleChange(value: any) {
    this.selectionChanged.emit(value);
  }

}
