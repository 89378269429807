<div (click)="tag == null ? null : onSort()" style="cursor: pointer; white-space: nowrap;">
  <span class="d-flex flex-row">
    <span [ngStyle]="{'margin-left': alignRight ? 'auto' : 'none'}">
      <span *ngIf="icon">

        <i class="ki-outline ki-{{icon}} fs-2x"
           [ngClass]="{ 'text-primary' : sortOrder != 0 }"></i>

      </span>
      <span *ngIf="header" [ngClass]="{ 'text-primary' : sortOrder != 0 }">{{header}}</span>


      <ng-container *ngIf="tag != null">
        <i *ngIf="sortOrder == 0" class="ki-outline ki-arrow-up-down fs-5"></i>

        <i *ngIf="sortOrder == 1" class="ki-outline ki-arrow-up text-primary fs-5"></i>

        <i *ngIf="sortOrder == 2" class="ki-outline ki-arrow-down text-primary fs-5"></i>

        <i *ngIf="sortOrder == 3" class="ki-outline ki-arrow-up-down text-primary fs-5"></i>
      </ng-container>

    </span>
  </span>
</div>
