import { Component } from '@angular/core';

@Component({
  selector: 'fit-scatter-chart-skeleton',
  templateUrl: './scatter-chart.skeleton.component.html'
})

export class ScatterChartSkeletonComponent {
  animation = 'pulse';
  yTheme = { width: '20px', height: '5px', margin: '0px' };
  dotTheme = { width: '15px', height: '15px', margin: '0px' };
  xTheme = { width: '55px', height: '5px'};
}
