import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  selector: 'fit-wizard'
})
export class WizardComponent {

  @Input()
  steps: string[];

  @Input()
  hasNextStep: boolean;

  @Input()
  hasPreviousStep: boolean;

  @Input()
  canGoNextStep: boolean;

  @Input()
  canGoPreviousStep: boolean;

  @Input()
  canCancel: boolean;

  @Input()
  canFinish: boolean;

  @Input()
  activeStepIndex = 0;

  @Input()
  containerClass = '';

  @Output()
  cancelClick: EventEmitter<void> = new EventEmitter();

  @Output()
  nextClick: EventEmitter<void> = new EventEmitter();

  @Output()
  previousClick: EventEmitter<void> = new EventEmitter();

  @Output()
  finishClick: EventEmitter<void> = new EventEmitter();

  get onLastStep(): boolean {
    return this.activeStepIndex === this.steps.length - 1;
  }

}
