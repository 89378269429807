import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Editor } from '@shared/editors/editor';

@Component({
  selector: 'fit-multiselect-editor',
  templateUrl: './multiselect.editor.html',
  styleUrls: ['./editor.scss', './multiselect.editor.scss']
})
export class MultiSelectEditor extends Editor {

  @Input() width = '100%';

  @Input() selectedOptions = new Array<any>();

  @Input() options = new Array<any>();

  @Input() value: string;

  @Input() required = true;

  @Output() optionAdded = new EventEmitter<any>();
  
  @Output() optionRemoved = new EventEmitter<any>();

  selectedOption: any;

  hoveringOption: any = null;

  controlTouched = false;

  ngOnInit() {
    this.selectedOptions?.forEach(selectedOption => {
      this.removeAvailableOption(selectedOption);
    });

    this.setSelectedOption();
  }

  ngOnChanges() {
    this.selectedOptions?.forEach(selectedOption => {
      this.removeAvailableOption(selectedOption);
    });
  }

  addOption() {
    if (this.selectedOption) {
      this.selectedOptions.push(this.selectedOption);
      this.optionAdded.emit(this.selectedOption);
  
      this.removeAvailableOption(this.selectedOption);
      this.setSelectedOption();
    }
  }

  deleteOption(option: any) {
    const index = this.selectedOptions.findIndex(o => o == option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    }
    this.optionRemoved.emit(option);

    this.options.push(option);
  }
  
  get hasSelectedOptions(): boolean {
    return this.selectedOptions?.length > 0;
  }

  get hasSelectedOption(): boolean {
    return this.selectedOption;
  } 

  get isInvalid(): boolean {
    return this.controlTouched
           && this.required
           && this.selectedOptions?.length == 0;
  }

  optionMouseEnter(_: MouseEvent, option: any): void {
    this.hoveringOption = option;
  }

  optionMouseLeave(_: MouseEvent, __: any): void {
    this.hoveringOption = null;
  }

  isHovering(option: any): boolean {
    return this.hoveringOption === option;
  }

  markAsTouched() {
    this.controlTouched = true;
  }

  setSelectedOption() {
    this.selectedOption = this.options.length ? this.options[0] : null;
  }

  removeAvailableOption(option: any) {
    const index = this.options.findIndex(o => this.isEqual(o, option));
    if (index > -1) {
      this.options.splice(index, 1);
    }
  }

  isEqual(option1: any, option2: any) {
    const keys1 = Object.keys(option1);
    
    for (let key of keys1) {
      if (option1[key] !== option2[key]) {
        return false;
      }
    }

    return true;
  }
}
