import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'fit-form-skeleton',
  templateUrl: './form.skeleton.component.html'
})

export class FormSkeletonComponent implements OnChanges {
  animation = 'pulse';

  @Input() editorCount = 3;
  @Input() setWidth = '440px';
  @Input() setHeight = '35px';
  @Input() setMargin = '0px';

  titleTheme = { width: '150px', height: '25px', margin: '5px' };
  editorTheme = { width: this.setWidth, height: this.setHeight, margin: this.setMargin };

  ngOnChanges() {
    this.editorTheme = { width: this.setWidth, height: this.setHeight, margin: this.setMargin };
  }
}
