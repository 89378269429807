import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Editor } from '@shared/editors/editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { EditState } from '@shared/types/edit.state';
import { DocumentViewModel } from '@shared/viewmodels/document.viewmodel';

@Component({
  selector: 'fit-image-editor',
  templateUrl: './image.editor.html',
  styleUrls: ['./editor.scss', './image.editor.scss']
})
export class ImageEditor extends Editor {

  @Input()
  label = '';

  @Input()
  defaultUrl = '/assets/media/svg/files/blank-image.svg';

  @Input()
  isReadOnly = false;

  @Input()
  showFullscreen = true;

  @Input()
  fullscreenHeader: string;

  constructor(private tooltipService: ToggleTooltipsService,
    private toastrService: ToastrService) {
    super(tooltipService);
  }

  onSelect(event): void {
    const file = (event.target as HTMLInputElement).files[0];

    if (file) {
      var index = file.name.lastIndexOf(".");
      var length = file.name.length;
      var fileType = file.name.substring(index, length);

      if ([".png", ".jpg", ".jpeg", ".PNG", ".JPG", ".JPEG"].includes(fileType)) {
        this.control.setValue(fileToDocumentViewModel(file));
        this.control.markAsTouched();
        this.control.markAsDirty();
        this.control.updateValueAndValidity();
      }
      else {
        this.toastrService.error("Cannot upload a file that's not a .png, .png or .jpeg", "Unexpected file type");
      }
    }
  }

  onRemove(): void {
    this.control.reset();
    this.control.markAsDirty();
  }

  get displayUrl() {
    return this.control.value?.blobLink ?? this.defaultUrl
  }

}

const fileToDocumentViewModel = (file: File): DocumentViewModel => {
  const viewModel = new DocumentViewModel();
  viewModel.file = file;
  viewModel.fileName = file.name;
  viewModel.fileType = file.type;
  viewModel.filesizeKb = file.size / 1000;
  viewModel.editState = EditState.added;

  // File Preview Url
  const reader = new FileReader();
  reader.onload = () => {
    viewModel.blobLink = reader.result as string;
  }
  reader.readAsDataURL(file)

  return viewModel;
};
