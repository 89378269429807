import { Component } from '@angular/core';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-password-editor',
  templateUrl: './password.editor.html',
  styleUrls: ['./editor.scss', './password.editor.scss']
})
export class PasswordEditor extends InputEditor {
  
  passwordVisible = false;

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('text', toggleTooltipsService);
  }

  toggleVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  

}
