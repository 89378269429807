<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>
      </div>
    </div>
  </div>
  <div class="pt-4 d-flex flex-column">
    <div class="d-block">
      <div class="image-input image-input-outline" data-kt-image-input="true">

        <div class="image-input-wrapper w-125px h-125px" [ngStyle]="{'background-image': 'url('+ displayUrl + ')'}"></div>

        <label class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow" *ngIf="!isReadOnly"
               data-kt-image-input-action="change"
               title="Change avatar">
          <i class="bi bi-pencil-fill fs-7"></i>

          <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onclick="this.value=null"
                 (change)="onSelect($event)" />
          <input type="hidden" name="avatar_remove" />

        </label>

        <span *ngIf="control.value?.blobLink && !isReadOnly" class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="remove"
              title="Remove avatar"
              (click)="onRemove()">
          <i class="bi bi-x fs-2"></i>
        </span>

      </div>
    </div>
    <div *ngIf="control.invalid && control.touched && !isReadOnly"
         class="fv-plugins-message-container pt-2">
      <div class="fv-help-block"> {{getErrorMessage()}} </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

