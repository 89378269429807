import { ViewModel } from '@shared/viewmodels/viewmodel';

export class NameViewModel extends ViewModel {
  name: string;
  selected: boolean;

  constructor(obj?: NameViewModel) {
    super(obj);
    if (obj) {
      this.name = obj.name;
      this.selected = obj.selected;
    }
  }

  getShortName?(len: number) {
    if (this.name && this.name.length > len) {
      return this.name.substring(0, len) + '...';
    }
    return this.name;
  }

  toString?() {
    return !!this ? this.name : '';
  }
}
