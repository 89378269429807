import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';

export type DropdownDirection = 'top' | 'bottom-start' | 'bottom auto' | 'bottom-left' | 'bottom-right' | 'bottom-end';

@Component({
  selector: 'fit-ngdropdown',
  templateUrl: './ngdropdown.component.html',
  styleUrls: ['./ngdropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgDropdownComponent {

  @ViewChild('button')
  button: ElementRef<HTMLButtonElement>;

  @Input()
  direction: DropdownDirection = 'bottom auto';

  @Input()
  text: string;

  @Input()
  label: string;

  @Input()
  class = "btn-secondary btn-sm";

  @Input()
  showToggleIcon = true;

  constructor() { }


}
