import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalButton } from '@shared/modals/modal.button';
import { ModalConfig } from '@shared/modals/modal.config.interface';
import { Modal } from '@shared/modals/modal.interface';
import { ModalResult } from '@shared/modals/modal.result';

export interface ConfirmModalConfig extends ModalConfig {
  message: string;
  title: string;
  size: string;
  primaryButton: ModalButton;
  secondaryButton: ModalButton;
  negativeButton: ModalButton;
}

class DefaultConfirmModalConfig implements ConfirmModalConfig {
  message: string;
  title: string;
  size: string = 'md';
  primaryButton = new ModalButton('Yes', 'btn-primary', ModalResult.primary);
  secondaryButton: ModalButton;
  negativeButton = new ModalButton('Cancel', 'btn-outline', ModalResult.negative);
}

@Component({
  templateUrl: './confirm.modal.component.html',
  styleUrls: ['./confirm.modal.component.scss']
})
export class ConfirmModalComponent implements Modal {

  private defaultConfig = new DefaultConfirmModalConfig();
  private subject: Subject<ModalResult> = new Subject<ModalResult>();

  message: string;
  title: string;
  size: string;
  primaryButton: ModalButton;
  secondaryButton: ModalButton;
  negativeButton: ModalButton;
  isLoading = false;
  visible = true;
  
  configure(config: ConfirmModalConfig): void {
    config = {
      ...this.defaultConfig,
      ...config
    };

    this.title = config.title;
    this.size = config.size;
    this.message = config.message;
    this.primaryButton = config.primaryButton;
    this.secondaryButton = config.secondaryButton;
    this.negativeButton = config.negativeButton;
  }

  get result(): Observable<ModalResult> {
    return this.subject.asObservable();
  }

  click(button: ModalButton) {
    this.startLoading();
    button.isLoading = true;
    this.subject.next(button.result);
    this.subject.complete();
  }

  startLoading() {
    this.isLoading = true;
  }

  finishLoading() {
    this.isLoading = false;
    if (this.primaryButton) this.primaryButton.isLoading = false;
    if (this.negativeButton) this.negativeButton.isLoading = false;
    if (this.secondaryButton) this.secondaryButton.isLoading = false;
  }

  show(): void {
    this.visible = true;
  }

  hide(): void {
    this.finishLoading();
    this.visible = false;
  }
}
