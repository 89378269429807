import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortOrder } from '@shared/types/sort.order';

@Component({
  selector: 'fit-sortheader',
  templateUrl: './sortheader.component.html'
})
export class SortheaderComponent implements OnInit {

  hover = false;

  @Input()
  alignRight = false;

  @Input()
  header: string;

  @Input()
  tag: string;

  @Input()
  sortOrder = SortOrder.none;

  @Input()
  icon: string;

  @Output()
  sortOrderToggle: EventEmitter<[string, SortOrder]> = new EventEmitter();

  ngOnInit() {
  }

  onSort() {
    if (this.sortOrder == SortOrder.none) {
      this.sortOrder = SortOrder.descending;
    }
    else if (this.sortOrder == SortOrder.descending) {
      this.sortOrder = SortOrder.ascending;
    }
    else if (this.sortOrder == SortOrder.ascending) {
      this.sortOrder = SortOrder.null;
    }
    else if (this.sortOrder == SortOrder.null) {
      this.sortOrder = SortOrder.none;
    }
    this.sortOrderToggle.emit([this.tag, this.sortOrder]);
  } 
}
