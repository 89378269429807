
<div class="radio radio-outline" [style.margin-right.px]="isInline ? 13: 0" [ngClass]="isInline ? '': 'my-2'">
  <input type="radio"
         [formControl]="control"
         [value]="optionValue.value"
         [attr.disabled]="isReadOnly ? true : null"
         [name]="groupId" />
  <span [style.margin-right.px]="isInline ? 13: 0"></span>
  <div class="d-flex position-relative">
    {{label}}
    <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

    <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
       [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
       (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
       tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
    </i>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

