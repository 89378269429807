import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'fit-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  animations: [
    trigger('openClose', [
      transition('void => *', [
        style({ opacity: 0 })
      ]),
      transition('* => void', [
        style({ opacity: 1 }),
        animate('200ms ease', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class ImageComponent implements OnInit {

  @Input()
  height = 'auto';

  @Input()
  width = '100%';

  @Input()
  aspectRatio: string;

  @Input()
  src: string;

  @HostBinding('style.paddingTop')
  paddingTop = 'auto';

  loading = false;

  loaded = false;

  loaderStyle: any;

  ngOnInit(): void {
    if (this.aspectRatio) {
      this.loaderStyle = {
        height: '0',
        paddingTop: ratioToPercent(this.aspectRatio)
      };
    } else {
      this.loaderStyle = {
        width: this.width,
        height: this.height
      };
    }

    setTimeout(() => {
      if (!this.loaded) {
        this.loading = true;
      }
    }, 10);
  }

  onLoad() {
    this.loading = false;
    this.loaded = true;
  }
}

const ratioToPercent = (ratio: string): string => {
  const [x, y] = ratio.split('/').map(i => parseInt(i, 10));
  return `${y / x * 100}%`;
};
