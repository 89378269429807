<div class="d-flex flex-row flex-wrap">
  <ng-container *ngFor="let i of [].constructor(cardCount)">

    <div class="col-md-4 col-xl-3">
      <div class="card card-custom gutter-b card-stretch mx-4 my-4">
        <div class="card-body">
          <div class="d-flex flex-column" style="min-height: 200px;">
            <div class="d-flex">
              <span class="flex-shrink-0 svg-icon svg-icon-2x svg-icon-primary me-3">
                <ngx-skeleton-loader [animation]="animation" [theme]="iconTheme"></ngx-skeleton-loader>
              </span>
              <div class="d-flex flex-column me-auto">
                <ngx-skeleton-loader [animation]="animation" [theme]="titleTheme"></ngx-skeleton-loader>
                <ngx-skeleton-loader [animation]="animation" [theme]="subtitleTheme"></ngx-skeleton-loader>
              </div>
            </div>
            <div>
              <ngx-skeleton-loader [animation]="animation" [theme]="contentTheme"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
