import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'fit-icon-button',
  templateUrl: './icon.button.component.html',
  styleUrls: ['./icon.button.component.css']
})
export class IconButtonComponent {

  @Input()
  busyLoading: boolean;

  @Input()
  icon: string;

  @Input()
  caption: string;

  @Input()
  title: string = '';

  @Input()
  busyCaption = 'Loading';

  @Input()
  size = 'sm';

  @Input()
  spinnerSize = 'sm';

  @Input()
  iconSize = '2x';

  @Input()
  class = 'btn-primary';

  @Input()
  wrapperClass = 'py-2 my-n2';

  @Output()
  onClick = new EventEmitter<MouseEvent>();

  @Input()
  isDisabled = false;

  @Input()
  width: string;

}
