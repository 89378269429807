import { AuthModel } from '@shared/models/auth.model';
import { SystemPermission } from '@shared/types/domain/system-permission';
import { NavigationPermissionsViewModel } from '@shared/viewmodels/domain/navigation.permissions.viewmodel';
import { PermissionViewModel } from '@shared/viewmodels/domain/permission.viewmodel';
import { ViewModel } from '@shared/viewmodels/viewmodel';

export class UserViewModel extends ViewModel {

  auth: AuthModel;
  firstName: string;
  lastName: string;
  email?: string;
  password: string;
  mobileNumberCode: string;
  mobileNumber: string;
  landlineNumberCode: string;
  landlineNumber: string;
  isAdministrator = false;
  isDeleted = false;
  avatarUrl: string = 'assets/media/svg/avatars/blank.svg';
  permissions: PermissionViewModel[] = [];
  navigationPermissions: NavigationPermissionsViewModel;

  constructor(user?: UserViewModel) {
    super(user);
    if (user) {
      this.auth = user?.auth;
      this.firstName = user?.firstName;
      this.lastName = user?.lastName;
      this.email = user?.email;
      this.password = user?.password;
      this.mobileNumberCode = user?.mobileNumberCode;
      this.mobileNumber = user?.mobileNumber;
      this.landlineNumberCode = user?.landlineNumberCode;
      this.landlineNumber = user?.landlineNumber;
      this.isAdministrator = user?.isAdministrator;
      this.isDeleted = user?.isDeleted;
      this.avatarUrl = !!user?.avatarUrl ? user?.avatarUrl + `?v=${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}` : 'assets/media/svg/avatars/blank.svg';
      this.permissions = user.permissions ? user?.permissions.map(t => new PermissionViewModel(t)) : new Array<PermissionViewModel>();
      this.navigationPermissions = new NavigationPermissionsViewModel(user?.navigationPermissions);
    }
  }

  toString() {
    return `${this.firstName} ${this.lastName}`;
  }
}
