import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'list'
})
export class ListPipe implements PipeTransform {
  transform(input:Array<any>, sep = ', ', maxLength: number = null): string {
    const list = input.join(sep);
    
    if (maxLength) {
      if (list.length > maxLength) {
        return list.substring(0, maxLength) + '...';
      }
    }

    if (list.length == 0) {
      return '-';
    }
    
    return list;
  }
}