import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestServiceBase } from '@shared/services/rest.service.base';
import { CountryViewModel } from '@shared/viewmodels/country.viewmodel';
import { CurrencyViewModel } from '@shared/viewmodels/currency.viewmodel';
import { environment } from '@environments/environment';
import { AuthService } from '@auth/index';

@Injectable()
export class LocationService extends RestServiceBase {

  private URL = environment.apiUrl + '/reference-location-';

  constructor(httpClient: HttpClient, authService: AuthService) {
    super(httpClient, authService);
  }

  getCountries(): Observable<Array<CountryViewModel>> {
    return this.get<Array<CountryViewModel>>(this.URL + `get-countries`);
  }

  getCurrencies(): Observable<Array<CurrencyViewModel>> {
    return this.get<Array<CurrencyViewModel>>(this.URL + `get-currencies`);
  }

}
