import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconManagementService {
  constructor() { }

  public get CompaniesIcon() {
    return 'finance/fin001.svg';
  }

  public get RolesIcon() {
    return 'security-user';
  }

  public get UsersIcon() {
    return 'communication/com014.svg';
  }

  public get ChartDashboard() {
    return 'graphs/gra011.svg';
  }

  public get LookupsIcon() {
    return 'arrows/arr042.svg';
  }

  public get PortalIcon() {
    return 'text/txt010.svg';
  }

  public get PipelineIcon() {
    return 'layouts/lay003.svg';
  }

  public get ReportsIcon() {
    return 'general/gen005.svg';
  }

  public get MilestonesIcon() {
    return 'maps/map001.svg';
  }

  public get ApplicationIcon() {
    return 'general/gen005.svg';
  }

  public get ArchivesIcon() {
    return 'finance/fin005.svg';
  }
}
