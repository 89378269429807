<div class="row" style="height:100%">
  <div class="col-sm-12 col-md-1">
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
    <div style="height:7rem"></div>
    <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
    <div style="height:5rem"></div>
    <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
    <div style="height:15rem"></div>
    <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
    <div style="height:12rem"></div>
    <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="xTheme"></ngx-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
    <div style="height:17rem"></div>
    <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-1"></div>

</div>
