import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shortnumber'})
export class ShortNumberPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    var exp, rounded,
      suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
    let currency = "";

    if (!isNumeric(input)) {
      if (!isNumeric(input.substring(0, 1))) {
        currency = input.substring(0, 1);
        input = parseFloat(input.substring(1).replace(/[ ,.]/g, ""));
      }
    }

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return currency + input;
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));

    return currency + (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  }

}

function isNumeric(num) {
  return !isNaN(num)
}
