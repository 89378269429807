import { Injectable, QueryList } from '@angular/core';
import { SlideOutDirective } from '@shared/directives/slide-out/slide-out.directive';

@Injectable()
export class SlideoutService {
  private slideouts: QueryList<SlideOutDirective>;

  constructor() {}

  open(id: string) {
    this.slideouts.forEach(s => s.id == id ? s.show(): s.hide());
  }

  close(id?: string) {
    if (id) {
      this.slideouts.forEach(s => s.id == id ? s.hide() : null);
    } else {
      this.slideouts.forEach(s => s.hide());
    }
  }

  updateSlideouts(items: QueryList<SlideOutDirective>) {
    this.slideouts = items;
  }

}
