import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { finalize, tap } from 'rxjs/operators';
import { Editor } from '@shared/editors/editor';
import { LocationService } from '@shared/services/location.service';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { CountryViewModel } from '@shared/viewmodels/country.viewmodel';

@Component({
  selector: 'fit-phone-editor',
  templateUrl: './phone.editor.html',
  styleUrls: ['./editor.scss', './phone.editor.css']
})
export class PhoneEditor extends Editor implements OnInit, OnChanges {
  @Input() codeControl: FormControl;
  @Input() codeWidth = '100%';
  @Input() phoneWidth = '100%';
  @Input() defaultCode: string;

  countries: CountryViewModel[];

  busyLoading = false;

  constructor(toggleTooltipsService: ToggleTooltipsService,
    private locationService: LocationService) {
    super(toggleTooltipsService);
  }

  ngOnInit() { 
    this.locationService.getCountries().pipe(
      tap(() => this.busyLoading = true),
      finalize(() => this.busyLoading = false)
    ).subscribe(data => this.countries = data.map(c => new CountryViewModel(c)));
  }

  ngOnChanges(): void {
    if (this.defaultCode && this.defaultCode.length > 0 && this.codeControl.value == null) {
      this.codeControl.setValue(this.defaultCode);
    }
  }
}
