<div *ngIf="label">
  <p class="font-weight-bold my-2">{{label}}</p>
</div>
<div ngbDropdown [placement]="direction" class="d-inline-block">
  <button class="btn"
          [ngClass]="class"
          [class.dropdown-toggle]="showToggleIcon"
          type="button"
          ngbDropdownToggle
          #button>
    <ng-content select="[icon]"></ng-content>
    {{ text }}
    <ng-content select="[toolbar]"></ng-content>
  </button>
  <div ngbDropdownMenu aria-labelledby="id">
    <ng-content></ng-content>
  </div>
</div>

