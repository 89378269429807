import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/index';

import { catchError, Observable, of, throwError } from 'rxjs';


@Injectable()
export class UnauthorizedHttpInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((res: HttpErrorResponse) => {
        if (res.status === 401) {
          this.authService.logout();
          return of(null);
        }
        return throwError(res);
      })
    );
  }
}
