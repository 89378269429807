import { NameViewModel } from "@shared/viewmodels/name.viewmodel";

export class NameValueViewModel<TValue> extends NameViewModel {

  static create<TValue>(name: string = '', value: TValue = null, selected: boolean = false) {
    const vm = new NameValueViewModel<TValue>();
    vm.name = name;
    vm.value = value;
    vm.selected = selected;

    return vm;
  }

  public value: TValue;

  constructor(obj?: NameValueViewModel<TValue>) {
    super(obj);
    if (obj) {
      this.value = obj.value;
    }
  }

  getName(len: number) {
    if (this?.name.length > len) {
      return this.name.substring(0, len - 2) + '..';
    }
    return this.name;
  }

  getValue(len: number) {
    if (this.value && this.value.toString().length > len) {
      return this.value.toString().substring(0, len - 2) + '..';
    }
    return this.value?.toString();
  }

}
