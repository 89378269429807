import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Form } from '@shared/forms/form';
import { NameValueViewModel } from '@shared/viewmodels/name.value.viewmodel';

@Component({
  selector: 'fit-test-editors',
  templateUrl: './test.editors.component.html'
})
export class TestEditorsComponent {

  form = new DetailsForm();
  radioOptions = new Array<NameValueViewModel<string>>();
  selectOptions = new Array<string>();

  constructor() {
    const n1 = new NameValueViewModel<string>();
    n1.name = "Option A";
    n1.value = "Option A";

    const n2 = new NameValueViewModel<string>();
    n2.name = "Option B";
    n2.value = "Option B";

    this.radioOptions.push(n1);
    this.radioOptions.push(n2);

    const option1 = "Residential";
    const option2 = "Office";
    this.selectOptions.push(option1);
    this.selectOptions.push(option2);
  }

}

class DetailsForm extends Form {
  name = this.addTypedControl<string>('name', [Validators.required, Validators.maxLength(250)]);
  description = this.addTypedControl<string>('description', [Validators.required, Validators.maxLength(500)]);
  iconName = this.addTypedControl<string>('iconName', [Validators.required]);
  colour = this.addTypedControl<string>('colour', [Validators.required]);
  radio = this.addTypedControl<string>('radio', [Validators.required]);
  checkbox = this.addTypedControl<string>('checkbox', [Validators.required]);
  amount = this.addTypedControl<number>('amount', [Validators.required]);
  date = this.addTypedControl<Date>('date', [Validators.required]);
  number = this.addTypedControl<number>('number', [Validators.required]);
  code = this.addTypedControl<string>('code', [Validators.required]);
  phoneNumber = this.addTypedControl<string>('phoneNumber', [Validators.required]);
  term = this.addTypedControl<NameValueViewModel<number>>('term', [Validators.required]);
  termValue = this.addTypedControl<number>('term', [Validators.required]);
  email = this.addTypedControl<string>('email', [Validators.required]);
  select = this.addTypedControl<string>('select', [Validators.required]);
  time = this.addTypedControl<string>('time', [Validators.required]);

}
