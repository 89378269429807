import { EventEmitter, Injectable, Component } from '@angular/core';
import { Editor } from '@shared/editors/editor';

@Injectable()
export class ToggleTooltipsService {

  tooltips = new EventEmitter();

  toggleTooltips(id: Editor) {
    this.tooltips.emit(id);
  }
}
