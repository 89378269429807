<div [ngClass]="wrapperClass" [style.width]="width ? width : null">
  <button type="button"
          [class]="isDisabled ? 'btn btn-outline ' + 'btn-' + size + ' fs-' + size : 'btn btn-outline ' + class + ' btn-' + size + ' fs-' + size "
          [ngClass]="{'btn font-weight-bold me-1 px-3': true}"
          [disabled]="isDisabled || busyLoading"
          [title]="title"
          (click)="onClick.next($event)">
    <div class="d-flex flex-row w-100 justify-content-center align-items-center">
      <i *ngIf="!busyLoading && icon" [class]="'ki-solid '+ 'fs-'+iconSize + ' ki-' + icon"></i>

      <span *ngIf="busyLoading" class="align-self-center spinner-border p-sm-6" [ngClass]="'spinner-border-'+ spinnerSize"></span>
      <span *ngIf="caption" class="ms-2 me-1 align-self-center"
            [ngStyle]="{'margin-top.px': icon ? '0' : '1', 'margin-bottom.px': icon ? '0' : '1'}">
        {{ busyLoading ? busyCaption : caption }}
      </span>
    </div>
  </button>
</div>
