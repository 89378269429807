<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-12 col-md-9 px-2'">
    <div class="d-flex flex-row"
         [ngClass]="{'cursor-pointer':  !isReadOnly}">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>


      </div>
    </div>
  </div>
  <div [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-9'">
    <div class="input-group"
         [style.width]="width">
      <div class="input-group-prepend">
        <div class="input-group-text">

          <i class="ki-outline ki-time text-primary text-dark fs-2"></i>
        </div>
      </div>
      <input type="time"
             class="form-control"
             [ngClass]="{'is-invalid': control.invalid && control.touched, 'text-gray-600': isReadOnly}"
             [formControl]="control"
             [readOnly]="isReadOnly"
             [attr.placeholder]="placeHolder" />
    </div>
    <span *ngIf="helperText"
          class="form-text text-muted">{{ helperText }}</span>
    <div *ngIf="control.invalid && control.touched && !isReadOnly"
         class="fv-plugins-message-container">
      <div class="fv-help-block">{{ getErrorMessage() }} </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

