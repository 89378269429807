<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row"
         [ngClass]="{'cursor-pointer': labelClickable && !isReadOnly}"
         (click)="labelClickable ? toggleControl() : null">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

      </div>
    </div>
  </div>
  <div class="col-form-label px-6"
       [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-3 px-3'">
    <div class="form-check form-check-custom form-check-solid">
      <input class="form-check-input border align-self-start"
             type="checkbox"
             [readOnly]="isReadOnly"
             [ngClass]="control.value ? 'border-gray-100': 'border-gray-400'"
             [formControl]="control"
             [attr.disabled]="isReadOnly ? true : null" />
    </div>
    <div *ngIf="helperText"
         class="form-text text-muted">{{helperText}}</div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <span class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</span>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

