import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";

@Component({
  selector: 'fit-tab-label',
  templateUrl: './tab.label.component.html',
  styleUrls: ['./tab.label.component.scss']
})
export class TabLabelComponent {
  
  @Input()
  name: string;

  @Input()
  icon: string;

  @Input()
  valid: boolean = true;

  @Output()
  onTabSelected = new EventEmitter<string>();

  @HostListener('click') onSelect() {
    this.onTabSelected.emit(this.name);
  }

  @Input()
  @HostBinding("class.active")
  active: boolean;

}
