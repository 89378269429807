export enum AbstractDataType {
  none = 0,
  text = 1,
  integer = 2,
  bigInteger = 3,
  decimal = 4,
  float = 5,
  date = 6,
  boolean = 7,
  guid = 8,
  timestamp = 9,
  short = 10,
  dateTime = 11,
  varbinary = 12,
  file = 13,
  country = 14,
  currency = 15,
  money = 16,
  entity = 17,
  lookup = 18,
  reviewStatus = 19
}
