import { EditState } from '@shared/types/edit.state';
import { ViewModel } from '@shared/viewmodels/viewmodel';

export class DocumentViewModel extends ViewModel {
  file: File;
  fileName: string;
  fileType: string;
  blobName: string;
  blobLink: string;
  filesizeKb: number;

  get size(): number {
    return this.filesizeKb * 1000;
  }

  constructor(obj?: any) {
    super(obj);

    this.file = obj?.file;
    this.fileName = obj?.fileName;
    this.fileType = obj?.fileType;
    this.blobName = obj?.blobName;
    this.blobLink = obj?.blobLink;
    this.filesizeKb = obj?.filesizeKb ?? 0;
    this.editState = obj?.editState ?? EditState.unchanged;
  }

  get hasDocument(): boolean {
    return this.blobLink != null || this.file?.size > 0;
  }

}
