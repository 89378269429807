import * as moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class DateTime {

  private _moment: moment.Moment;

  constructor(date = new Date()) {
    this._moment = moment(date);
  }

  static fromDate(date: Date): DateTime {
    return new DateTime(date);
  }

  toDate(): Date {
    return this._moment.utc(true).toDate();
  }

  toString(format = 'YYYY-MM-DD'): string {
    return this._moment.format(format);
  }

  toIsoString(): string {
    return this._moment.toISOString();
  }

  difference(date: DateTime): TimeSpan {
    const end = moment(date.toDate());
    const timeSpan = new TimeSpan();
    timeSpan.seconds = this._moment.diff(end, 'seconds');
    timeSpan.minutes = this._moment.diff(end, 'minutes');
    timeSpan.hours = this._moment.diff(end, 'hours');
    timeSpan.days = this._moment.diff(end, 'days');
    timeSpan.weeks = this._moment.diff(end, 'weeks');
    timeSpan.months = this._moment.diff(end, 'months');
    timeSpan.years = this._moment.diff(end, 'years');
    return timeSpan;
  }

  clone(): DateTime {
    return new DateTime(this.toDate());
  }

  toLocal() : DateTime {
    this._moment = this._moment.local();
    return this;
  }

  toUTC(keepLocalTime: boolean = false) {
    this._moment = this._moment.utc(keepLocalTime);
    return this;
  }

  addSeconds(seconds: number): DateTime {
    this._moment.add(seconds, 'seconds');
    return this;
  }

  addMinutes(minutes: number): DateTime {
    this._moment.add(minutes, 'minutes');
    return this;
  }

  addHours(hours: number): DateTime {
    this._moment.add(hours, 'hours');
    return this;
  }

  addDays(days: number): DateTime {
    this._moment.add(days, 'days');
    return this;
  }

  addWeeks(weeks: number): DateTime {
    this._moment.add(weeks, 'weeks');
    return this;
  }

  addMonths(months: number): DateTime {
    this._moment.add(months, 'months');
    return this;
  }

  addYears(years: number): DateTime {
    this._moment.add(years, 'years');
    return this;
  }

  startOfDay(): DateTime {
    this._moment.startOf('day');
    return this;
  }

  endOfDay(): DateTime {
    this._moment.endOf('day');
    return this;
  }

  startOfMonth(): DateTime {
    this._moment.startOf('month');
    return this;
  }

  endOfMonth(): DateTime {
    this._moment.endOf('month');
    return this;
  }

  startOfYear(): DateTime {
    this._moment.startOf('year');
    return this;
  }

  endOfYear(date: Date): DateTime {
    this._moment.endOf('year');
    return this;
  }
}

export class TimeSpan {
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
