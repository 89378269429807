import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/index';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface HttpRequestOptions {
  goToServerErrorPage: boolean;
  retryCount: number;
}

export const defaultRequestOptions: HttpRequestOptions = {
  goToServerErrorPage: false,
  retryCount: 2
};

@Injectable()
export abstract class RestServiceBase {

  defaultRequestOptions = {
    goToServerErrorPage: false,
    retryCount: 2
  };

  constructor(protected httpClient: HttpClient,
    protected authService: AuthService) {
  }

  private getHeaders(options: HttpRequestOptions) {
    const headersObj = {
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'Authorization': this.authService.authToken,
      'x-functions-key': environment.apiKey
    };

    headersObj['fit-retry-count'] = `${options.retryCount}`;

    if (options.goToServerErrorPage) {
      headersObj['fit-server-error-page'] = `${options.goToServerErrorPage}`;
    }

    return new HttpHeaders(headersObj);
  }

  private getOptions(options: Partial<HttpRequestOptions>): HttpRequestOptions {
    return {
      ...this.defaultRequestOptions,
      ...options
    };
  }

  get<T>(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.get<T>(link, {
      headers: headers,
      params: params
    });
  }

  getText(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<string> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.get(link, {
      headers: headers,
      params: params,
      responseType: 'text'
    });
  }

  postForm<T>(url: string, body: FormData,
    options: Partial<HttpRequestOptions> = this.defaultRequestOptions,
    params?: HttpParams): Observable<HttpEvent<T>> {

    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.post<T>(url, body, {
      headers: headers,
      observe: 'events',
      params: params,
      reportProgress: true
    });
  }

  getBlob(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<Blob> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.get(link, {
      headers: headers,
      params: params,
      responseType: 'blob'
    });
  }


  post<T>(link: string, body: any, options: Partial<HttpRequestOptions> = this.defaultRequestOptions): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.post<T>(link, body, { headers: headers });
  }

  postBlob(link: string,
    body: any,
    options: Partial<HttpRequestOptions> = this.defaultRequestOptions,
    params?: HttpParams): Observable<Blob> {

    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.post(link, body, {
      headers: headers,
      params: params,
      responseType: 'blob'
    });
  }

  put<T>(link: string, body: any, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.put<T>(link, body, {
      headers: headers,
      params: params
    });
  }

  delete<T>(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.delete<T>(link, {
      headers: headers
    });
  }
}

