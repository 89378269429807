<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-12 col-md-9 px-2'">
    <div class="d-flex flex-row"
         [ngClass]="{'cursor-pointer': !isReadOnly}">
      <div class="d-flex position-relative">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>


      </div>
    </div>
  </div>
  <div class="dropdown-container"
       [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-9'">
    <div ngbDropdown [style.width]="width"
         class="dropdown dropdown-inline"
         (openChange)="onOpenChange($event)">

      <!--Select Icon-->
      <a class="btn btn-light-primary dropdown-toggle"
         data-toggle="dropdown"
         aria-expanded="false"
         ngbDropdownToggle
         [ngClass]="{'form-control is-invalid': control.invalid && control.touched}">
        <span *ngIf="selectedIcon; else showPlaceholder">

          <i class="ki-outline ki-{{selectedIcon}} text-primary fs-2"></i>

          {{ iconMap.get(selectedIcon) | titlecase}}
        </span>
        <ng-template #showPlaceholder>
          Select an icon
        </ng-template>
      </a>

      <!--Options-->
      <div ngbDropdownMenu
           class="dropdown-menu dropdown-menu-md py-5">
        <ul class="navi navi-hover navi-link-rounded-lg">
          <li ngbDropdownItem
              class="navi-item"
              *ngFor="let icon of iconMap | keyvalue"
              (click)="handleChange(icon.key)">
            <span class="navi-icon">

              <i class="ki-outline ki-{{icon.key}} text-primary fs-2"></i>
            </span>
            <span class="navi-text">{{icon.value}}</span>
          </li>
        </ul>
      </div>
    </div>

    <!--Helper Text-->
    <span *ngIf="helperText"
          class="form-text text-muted">{{helperText}}</span>

    <!--Error Message-->
    <div *ngIf="control.invalid && control.touched && !isReadOnly"
         class="fv-plugins-message-container">
      <div class="fv-help-block"> {{getErrorMessage()}} </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <span class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</span>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

