import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export class Form extends FormGroup {

  constructor() {
    super({});
  }

  addTypedControl<T>(name: string, validators: ValidatorFn[] = [], initialValue: T = null, enabled = true): FormControl<T> {
    const control = new FormControl<T>(initialValue, validators);
    enabled ? control.enable() : control.disable();
    this.addControl(name, control);
    return control;
  }

  addForm<T>(name: string, form: Form, options?: { emitEvent?: boolean; }): T {
    this.setControl(name, form);
    return form as unknown as T;
  }

  addFormArray<T extends Form>(name: string, form: FormArray<T>): FormArray<T> {
    this.addControl(name, form);
    return form as unknown as FormArray<T>;
  }
}

