import { Component } from '@angular/core';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-email-editor',
  templateUrl: './email.editor.html',
  styleUrls: ['./editor.scss']
})
export class EmailEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('text', toggleTooltipsService);
  }

}
