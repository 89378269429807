<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="required" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>
      </div>
    </div>
  </div>

  <div [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-9' ">
    <div class="d-flex flex-row" style="width: 100%" [ngClass]="{'mb-2': hasSelectedOptions }">

      <!--Select-->
      <ng-container *ngIf="!isReadOnly">
        <select class="form-control me-1"
                type="text"
                [ngClass]="{'is-invalid': isInvalid, 'text-gray-500': isReadOnly}"
                [(ngModel)]="selectedOption"
                (blur)="markAsTouched()"
                [style.width]="width">
          <option *ngFor="let option of options"
                  [ngValue]="value ? option[value] : option">
            {{option}}
          </option>
        </select>
      </ng-container>

      <ng-container *ngIf="isReadOnly">
        <input class="form-control"
               type="text"
               [style.width]="width"
               [attr.placeholder]="placeHolder"
               readonly />
      </ng-container>

      <!--Add button -->
      <div class="input-group-append flex-nowrap">
        <button type="button" class="btn font-weight-bold me-2 btn-light-primary d-flex flex-nowrap"
                (click)="addOption()"
                [disabled]="isReadOnly || !hasSelectedOption">

          <i class="ki-outline ki-plus-circle fs-2"></i>
          Add
        </button>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div *ngIf="isInvalid" class="fv-plugins-message-container">
        <div class="fv-help-block">
          At least one option must be selected.
        </div>
      </div>
    </div>

    <!--Selected options-->
    <div class="selected-list" *ngIf="hasSelectedOptions">
      <ng-container *ngFor="let option of selectedOptions">
        <div class="selected-option"
             (mouseenter)="optionMouseEnter($event, option)"
             (mouseleave)="optionMouseLeave($event, option)">
          <div class="selected-option-label">
            {{option}}
          </div>
          <div class="delete-button" *ngIf="!isReadOnly && isHovering(option)">
            <a (click)="deleteOption(option)" class="navi-link pointer-cursor" title="Delete">

              <i class="ki-outline ki-trash text-danger"></i>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

