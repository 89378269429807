import { DecimalPipe } from '@angular/common';
import { Component, Input, ViewChild, ViewChildren } from '@angular/core';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-number-editor',
  templateUrl: './number.editor.html',
  styleUrls: ['./editor.scss']
})
export class NumberEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService, decimalPipe: DecimalPipe) {
    super('number', toggleTooltipsService, decimalPipe);
  }

  enforceBoundries(event: any) {
    if (event.target.value > this.max) {
      this.control.setValue(this.max);
    }
    if (this.min != null && this.min != undefined) {
      if (event.target.value < this.min) {
        this.control.setValue(this.min);
      }
    }
  }

  @ViewChildren('realInput') input;

  @Input()
  min = 0;

  @Input()
  max: number;

  @Input() 
  step: number;
   
  @Input()
  append: string

  @Input()
  numberPipeConfig: string = '1.0-5'

  @Input()
  thousandsSeparator = ' ';

  @Input()
  defaultMargins = true;

  editing: boolean = false;

  onBlur() {
    this.editing = false;
  }

  onFocus() {
    this.editing = true;

    setTimeout(() => this.input.first.nativeElement.focus(), 1);
    
  }
}
