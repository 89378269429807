<div class="row" style="height:100%">
  <div class="col-sm-12 col-md-1">
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" [theme]="barShortTheme"></ngx-skeleton-loader>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" [theme]="barMedTheme"></ngx-skeleton-loader>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" [theme]="barMedTheme"></ngx-skeleton-loader>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" [theme]="barTallTheme"></ngx-skeleton-loader>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: center">
    <ngx-skeleton-loader [animation]="animation" [theme]="barShortTheme"></ngx-skeleton-loader>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1"></div>

</div>
