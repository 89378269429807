import { Component } from '@angular/core';

@Component({
  selector: 'fit-line-chart-skeleton',
  templateUrl: './line-chart.skeleton.component.html'
})

export class LineChartSkeletonComponent {
  animation = 'pulse';
  yTheme = { width: '20px', height: '5px', margin: '0px' };
  straightBarTheme = { width: '102%', height: '7px', margin: '0px' };
  angledBarTheme = { width: '122%', height: '7px', margin: '0px' };
  xTheme = { width: '55px', height: '5px'};
}
