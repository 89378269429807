import { ViewModel } from '@shared/viewmodels/viewmodel';

export class PermissionViewModel extends ViewModel {
  subject: string;
  action: string;
  description: string;

  constructor(obj?: PermissionViewModel) {
    super(obj);
    if (obj) {
      this.subject = obj.subject;
      this.action = obj.action;
      this.description = obj.description;
    }
  }
}
