import { Inject, Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ServerErrorHttpInterceptor implements HttpInterceptor {

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  constructor(private router: Router,
    private location: Location,
    @Inject(Injector) private injector: Injector) { } 

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry(parseInt(req.headers.get('fit-retry-count'))),
      catchError((res) => {
        if (res.status === 500 || res.status === 400) {
          const getErrorMessage = (): Promise<string> => {
            return new Promise((resolve, _) => {
              // We get an object back if the expected response type was 'blob'
              // Now we need to parse the blob as a string to get the correct error message from the server
              if (res.error) {
                if (typeof (res.error) === 'object') {
                  const reader = new FileReader();
                  reader.addEventListener('loaded', (e: ProgressEvent<FileReader>) => {
                    const text = (e.target as any).result;
                    resolve(text);
                  });
                  reader.readAsText(res.error);
                } else {
                  resolve(res.error);
                }
              } else {
                resolve('Internal server error occurred');
              }
            });
          };

          getErrorMessage().then(errMsg => {
            if (res.headers.get('fit-server-error-page') === 'true') {
              // Skip location change enables us to go to the server error page without
              // changing the URL, thus allowing users to refresh the page to reload 
              // instead of going back in the navigation history
              this.router.navigateByUrl('/error', { skipLocationChange: true });
  
              // We need to replace the state of the url, as we can't pass the state to the 'navigateByUrl'
              // as no navigation actually takes place (skipLocationChange is simulated navigation)
              this.location.replaceState(this.router.url, '', {
                message: errMsg
              });
            } else {
              this.toastr.error(errMsg);
            }
          });

        }
        return throwError(res);

      })
    );
  }

}
