<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-12 col-md-9 px-2'">
    <div class="d-flex flex-row"
         [ngClass]="{'cursor-pointer': !isReadOnly}">
      <div class="d-flex position-relative">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>


      </div>
    </div>
  </div>
  <div class="col-form-label"
       [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-9'">
    <div [ngClass]="{'radio-inline': isInline}">
      <fit-radio-button-editor *ngFor="let option of options"
                               [control]="control"
                               [label]="option.name"
                               [optionValue]="option"
                               [isReadOnly]="isReadOnly"
                               [groupId]="groupId"
                               [isInline]="isInline">
      </fit-radio-button-editor>
    </div>
    <span *ngIf="helperText"
          class="form-text text-muted">
      {{helperText}}
    </span>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

