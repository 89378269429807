import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { FlatPickrOutputOptions } from 'angularx-flatpickr/lib/flatpickr.directive';


@Component({
  selector: 'fit-daterange-editor',
  templateUrl: './date-range.editor.html',
  styleUrls: ['./date-range.editor.scss']
})
export class DateRangeEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('date', toggleTooltipsService);
  }

  @Output()
  onClear = new EventEmitter();

  @Input()
  width = '100%';

  @Input()
  min: string;

  @Input()
  max: string;

  @Input()
  defaultMargins = true;

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  @Input()
  showSymbol = true;

  @Input()
  class = true;

  @Input()
  dateFormat = 'd/m/y';

  clearInput() {
    this.control.setValue(null);
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.onClear.emit();
  }

  onOpen(msg: FlatPickrOutputOptions) {
    msg.instance.input['readOnly'] = true;
  }

  onClose(msg: FlatPickrOutputOptions) {

    msg.instance.input['readOnly'] = false;
    msg.instance.input.blur();
  }
}
