import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { map, scan, startWith, tap } from 'rxjs/operators';

export type DropdownDirection = 'up' | 'down';

@Component({
  selector: 'fit-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownComponent implements AfterViewInit {

  @ViewChild('button')
  button: ElementRef<HTMLButtonElement>;

  @Input()
  direction: DropdownDirection = 'down';

  @Input()
  text: string;

  @Input()
  class = "md";

  @Input()
  btnClass = "btn-secondary";

  @Input()
  showToggleIcon = true;

  dropdownClasses$: Observable<string[]>;

  menuClasses$: Observable<string[]>;

  active$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {

    const buttonClick = fromEvent(this.button.nativeElement, 'click').pipe(
      tap(event => event.stopPropagation()),
      map(() => 'button_click')
    );

    const documentClick = fromEvent(this.document, 'click').pipe(
      map(() => 'document_click')
    );

    this.active$ = merge(
      buttonClick,
      documentClick
    ).pipe(
      startWith(false),
      scan((active: boolean, eventType: string) => {
        switch (eventType) {
          case 'button_click':
            return !active;
          case 'document_click':
            return false;
        }
      }, false)
    );

    this.menuClasses$ = this.active$.pipe(
      startWith(['']),
      map((active) => {
        return active ? ['dropdown-menu show'] : ['dropdown-menu'];
      })
    );

    this.dropdownClasses$ = this.active$.pipe(
      startWith([this.direction === 'down' ? 'dropdown' : 'dropup']),
      map((active) => {
        const position = [this.direction === 'down' ? 'dropdown' : 'dropup'];
        return active ? [...position, 'show'] : [...position];
      })
    );

    this.cdr.detectChanges();
  }

}
