import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CleanSlate } from '@shared/components/clean.slate.component';
import { CollapseHeaderComponent } from '@shared/components/collapseheader.component';
import { DropdownComponent } from '@shared/components/dropdown.component';
import { GridButtonComponent } from '@shared/components/grid.button.component';
import { IconButtonComponent } from '@shared/components/icon.button.component';
import { ImageComponent } from '@shared/components/image.component';
import { PaginatorComponent } from '@shared/components/paginator.component';
import { PdfDropzonePreviewComponent } from '@shared/components/pdf.dropzone.preview.component';
import { ProgressBar } from '@shared/components/progress.bar';
import { BarChartSkeletonComponent } from '@shared/components/skeletons/bar-chart.skeleton.component';
import { CardsSkeletonComponent } from '@shared/components/skeletons/cards.skeleton.component';
import { FormSkeletonComponent } from '@shared/components/skeletons/form.skeleton.component';
import { GridSkeletonComponent } from '@shared/components/skeletons/grid.skeleton.component';
import { LineChartSkeletonComponent } from '@shared/components/skeletons/line-chart.skeleton.component';
import { PieChartSkeletonComponent } from '@shared/components/skeletons/pie-chart.skeleton.component';
import { ScatterChartSkeletonComponent } from '@shared/components/skeletons/scatter-chart.skeleton.component';
import { SortheaderComponent } from '@shared/components/sortheader.component';
import { TabBodyComponent } from '@shared/components/tab.body.component';
import { TabLabelComponent } from '@shared/components/tab.label.component';
import { TabsComponent } from '@shared/components/tabs.component';
import { TestEditorsComponent } from '@shared/components/test.editors.component';
import { WizardComponent } from '@shared/components/wizard/wizard.component';
import { AutoExpandingTextarea } from '@shared/directives/auto-expanding-textarea.directive';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { HoverClassDirective } from '@shared/directives/hover.class.directive';
import { NoDecimalDirective } from '@shared/directives/no.decimal.directive';
import { OnlyNumberDirective } from '@shared/directives/number.directive';
import { SlideOutCollapsableDirective } from '@shared/directives/slide-out/slide-out-collapsable.directive';
import { SlideOutHideableDirective } from '@shared/directives/slide-out/slide-out-hideable.directive';
import { SlideOutDirective } from '@shared/directives/slide-out/slide-out.directive';
import { SlideoutService } from '@shared/directives/slide-out/slide-out.service';
import { VarDirective } from '@shared/directives/var.directive';
import { CheckboxEditor } from '@shared/editors/checkbox.editor';
import { CurrencyEditor } from '@shared/editors/currency.editor';
import { DateRangeEditor } from '@shared/editors/date-range.editor';
import { DateEditor } from '@shared/editors/date.editor';
import { DateTimeEditor } from '@shared/editors/datetime.editor';
import { EmailEditor } from '@shared/editors/email.editor';
import { IconSelectEditor } from '@shared/editors/icon.editor';
import { ImageEditor } from '@shared/editors/image.editor';
import { MultiSelectEditor } from '@shared/editors/multiselect.editor';
import { NumberEditor } from '@shared/editors/number.editor';
import { PasswordEditor } from '@shared/editors/password.editor';
import { PdfEditor } from '@shared/editors/pdf.editor';
import { PhoneEditor } from '@shared/editors/phone.editor';
import { RadioButtonEditor } from '@shared/editors/radio.button.editor';
import { RadioEditor } from '@shared/editors/radio.editor';
import { RangeEditor } from '@shared/editors/range.editor';
import { SelectEditor } from '@shared/editors/select.editor';
import { TextEditor } from '@shared/editors/text.editor';
import { TextAreaEditor } from '@shared/editors/textarea.editor';
import { TimeEditor } from '@shared/editors/time.editor';
import { TypeaheadEditor } from '@shared/editors/typeahead.editor';
import { CanDeactivateGuard } from '@shared/guards/can.deactivate.guard';
import { ConflictHttpInterceptor } from '@shared/interceptors/conflict.http.interceptor';
import { ServerErrorHttpInterceptor } from '@shared/interceptors/server-error.http.interceptor';
import { UnauthorizedHttpInterceptor } from '@shared/interceptors/unauthorized.http.interceptor';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm.modal.component';
import { ModalHostDirective } from '@shared/modals/modal.host.directive';
import { ModalService } from '@shared/modals/modal.service';
import { ButtonColourPipe } from '@shared/pipes/button-colour.pipe';
import { ClockPipe } from '@shared/pipes/clock.pipe';
import { FileSizePipe } from '@shared/pipes/file.size.pipe';
import { ListPipe } from '@shared/pipes/list.pipe';
import { LogPipe } from '@shared/pipes/log.pipe';
import { ReplacePipe } from '@shared/pipes/replace.pipe';
import { SafePipe } from '@shared/pipes/safe.html.pipe';
import { ShortNumberPipe } from '@shared/pipes/short.number.pipe';
import { StringSplitPipe } from '@shared/pipes/string.split.pipe';
import { TimeToGoPipe } from '@shared/pipes/time-to-go.pipe';
import { FormControlPipe } from '@shared/pipes/typed-form-control.pipe';
import { WithLoadingPipe } from '@shared/pipes/with-loading.pipe';
import { YesNoPipe } from '@shared/pipes/yes.no.pipe';
import { DateService } from '@shared/services/date.service';
import { IconManagementService } from '@shared/services/icon-management.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { LocationService } from '@shared/services/location.service';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { ToolbarService } from '@shared/services/toolbar.service';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DxButtonModule, DxChartModule, DxDataGridModule, DxDropDownBoxComponent, DxDropDownBoxModule, DxFileUploaderModule, DxFormModule, DxNumberBoxModule, DxPopupModule, DxSelectBoxComponent, DxSelectBoxModule, DxSparklineModule, DxTagBoxModule, DxTemplateModule, DxTreeViewModule, DxValidatorModule } from 'devextreme-angular';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgDropdownComponent } from './components/ngdropdown.component';
import { Editor } from './editors/editor';
import { InputEditor } from './editors/input.editor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ConflictHttpInterceptor, multi: true },
];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    MomentModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    ToastrModule.forRoot({
      timeOut: 4000
    }),
    NgxSkeletonLoaderModule,
    NgxDropzoneModule,
    NgxSliderModule,
    FlatpickrModule.forRoot(),
    DragDropModule,
    PdfViewerModule,
    InlineSVGModule,
    NgPipesModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DxDataGridModule, 
    DxTreeViewModule,
    DxTemplateModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxPopupModule,
    DxFormModule,
    DxFormModule,
    DxChartModule,
    DxSparklineModule,
    AbilityModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxDropDownBoxModule,
    DxNumberBoxModule,
    DxValidatorModule
  ],
  declarations: [
    AutoExpandingTextarea,
    AutofocusDirective,
    BarChartSkeletonComponent,
    GridButtonComponent,
    CardsSkeletonComponent,
    CheckboxEditor,
    CleanSlate,
    ClockPipe,
    CollapseHeaderComponent,
    ConfirmModalComponent,
    CurrencyEditor,
    DateEditor,
    DropdownComponent,
    Editor,
    EmailEditor,
    FileSizePipe,
    FormSkeletonComponent,
    GridSkeletonComponent,
    HoverClassDirective,
    IconButtonComponent,
    IconSelectEditor,
    ImageComponent,
    ImageEditor,
    InputEditor,
    LineChartSkeletonComponent,
    ListPipe,
    LogPipe,
    ModalHostDirective,
    MultiSelectEditor,
    NgDropdownComponent,
    NoDecimalDirective,
    NumberEditor,
    OnlyNumberDirective,
    OnlyNumberDirective,
    PaginatorComponent,
    PasswordEditor,
    PdfDropzonePreviewComponent,
    PdfEditor,
    PhoneEditor,
    PieChartSkeletonComponent,
    ProgressBar,
    RadioButtonEditor,
    RadioEditor,
    RangeEditor,
    SafePipe,
    ScatterChartSkeletonComponent,
    SelectEditor,
    ShortNumberPipe,
    SlideOutCollapsableDirective,
    SlideOutDirective,
    SlideOutHideableDirective,
    SortheaderComponent,
    StringSplitPipe,
    TabBodyComponent,
    TabLabelComponent,
    TabsComponent,
    TestEditorsComponent,
    TextAreaEditor,
    TextEditor,
    TimeEditor,
    TypeaheadEditor,
    VarDirective,
    WithLoadingPipe,
    WizardComponent,
    YesNoPipe,
    TimeToGoPipe,
    DateRangeEditor,
    ReplacePipe,
    FormControlPipe,
    DateTimeEditor,
    GridButtonComponent,
    ButtonColourPipe,
  ],
  exports: [
    AutoExpandingTextarea,
    AutofocusDirective,
    BarChartSkeletonComponent,
    CardsSkeletonComponent,
    CheckboxEditor,
    CleanSlate,
    ClockPipe,
    CollapseHeaderComponent,
    CurrencyEditor,
    DateEditor,
    DragDropModule,
    DropdownComponent,
    Editor,
    EmailEditor,
    FileSizePipe,
    FormSkeletonComponent,
    GridSkeletonComponent,
    HoverClassDirective,
    IconButtonComponent,
    IconSelectEditor,
    ImageComponent,
    ImageEditor,
    InlineSVGModule,
    InputEditor,
    LineChartSkeletonComponent,
    ListPipe,
    LogPipe,
    ModalHostDirective,
    MultiSelectEditor,
    NgDropdownComponent,
    NgbDatepickerModule,
    NgbModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
    NoDecimalDirective,
    NumberEditor,
    OnlyNumberDirective,
    OnlyNumberDirective,
    PaginatorComponent,
    PasswordEditor,
    PdfDropzonePreviewComponent,
    PdfEditor,
    PdfViewerModule,
    NgScrollbarModule,
    PhoneEditor,
    PieChartSkeletonComponent,
    ProgressBar,
    RadioButtonEditor,
    RadioEditor,
    RangeEditor,
    SafePipe,
    ScatterChartSkeletonComponent,
    SelectEditor,
    ShortNumberPipe,
    SlideOutCollapsableDirective,
    SlideOutDirective,
    SlideOutHideableDirective,
    SortheaderComponent,
    StringSplitPipe,
    TabBodyComponent,
    TabLabelComponent,
    TabsComponent,
    TestEditorsComponent,
    TextAreaEditor,
    TextEditor,
    TimeEditor,
    TypeaheadEditor,
    VarDirective,
    WithLoadingPipe,
    WizardComponent,
    YesNoPipe,
    NgPipesModule,
    TimeToGoPipe,
    DateRangeEditor,
    ReplacePipe,
    NgxMaskDirective,
    NgxMaskPipe,
    FormControlPipe,
    DateTimeEditor,
    DxDataGridModule,
    DxTreeViewModule,
    DxTemplateModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxPopupModule,
    DxFormModule,
    GridButtonComponent,
    DxTagBoxModule,
    DxChartModule,
    DxSparklineModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxTagBoxModule,
    AbilityModule,
    ButtonColourPipe,
    DxValidatorModule
  ],
  providers: [DecimalPipe, provideNgxMask(),
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability }]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LocationService,
        ModalService,
        SlideoutService,
        CanDeactivateGuard,
        ToggleTooltipsService,
        ToolbarService,
        LocalStorageService,
        IconManagementService,
        DateService
      ]
    };
  }
}

