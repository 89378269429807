import { v4 as uuidv4 } from 'uuid';
import { EditState } from '@shared/types/edit.state';

export class ViewModel {
  instanceId: string = uuidv4();
  rowVersion: string;
  editState: EditState;
  eTag: string;

  constructor(obj?: ViewModel) {
    this.instanceId = obj?.instanceId;
    this.rowVersion = obj?.rowVersion;
    this.editState = obj?.editState;
    this.eTag = obj?.eTag;
  }
}
