<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">

    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

      </div>
    </div>


  </div>

  <div [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-3'" class="position-relative">
    <div class="position-relative">
      <input class="form-control"
             type="text"
             [ngClass]="{'is-invalid': control.invalid && control.touched, 'text-gray-600': isReadOnly}"
             [formControl]="control"
             [style.width]="width"
             [style.height]="height"
             [readOnly]="isReadOnly"
             [attr.placeholder]="placeHolder"
             [pattern]="pattern ? pattern : null"
             [ngbTypeahead]="search"
             (selectItem)="!isReadOnly ? beforeSelect.emit($any($event).item) : null"
             (focus)="!isReadOnly ? onFocus.emit($any($event).target.value) : null"
             (click)="!isReadOnly ? onClick.emit($any($event).target.value) : null"
             [editable]="editable" />
      <div class="position-absolute translate-middle-y top-50 end-0 me-3">
        <span *ngIf="busyLoading" class="spinner-border text-primary spinner-border-md mr-6"></span>

        <i *ngIf="!busyLoading && !isReadOnly && control.value != null"
           (click)="$event.stopPropagation(); control.setValue(null)"
           title="Clear Input"
           class="ki-outline ki-cross-circle text-gray-600 hover-scale cursor-pointer fs-1"></i>
        <i *ngIf="!busyLoading && !isReadOnly && control.value != null" class="ki-outline ki-magnifier fs-2x"></i>


      </div>

    </div>
    <span *ngIf="helperText"
          class="form-text text-muted">{{ helperText }}</span>
    <div *ngIf="control.invalid && control.touched && !isReadOnly"
         class="fv-plugins-message-container">
      <div class="fv-help-block"> {{ getErrorMessage() }} </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

