import { Pipe, PipeTransform } from '@angular/core';
import { TimeSpan } from '@shared/types/date.time';

@Pipe({ name: 'clock' })
export class ClockPipe implements PipeTransform {

  transform(duration: TimeSpan): string {

    if (duration == null) {
      return '00 00:00:00';
    }

    return `${duration.days.toString().padStart(2, '0')} `
      + `${duration.hours.toString().padStart( 2, '0')}:`
      + `${duration.minutes.toString().padStart(2, '0')}:`
      + `${duration.seconds.toString().padStart(2, '0')}`;
  }
}
