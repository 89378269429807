<fit-text-editor [control]="form.name" [label]="'Name'" [fullWidth]="true" helperText="Do you need a text editor?" tooltip="put your text notes here">
</fit-text-editor>

<fit-textarea-editor [control]="form.description" [label]="'Description'" helperText="Do you need a text area editor?" tooltipTitle="Title" tooltip="put your test area notes here">
</fit-textarea-editor>

<fit-icon-editor [control]="form.iconName" [label]="'Icon'" helperText="Do you need a icon editor?" tooltipTitle="Title" tooltip="put your icon notes here" tooltipIcon="general/gen017.svg">
</fit-icon-editor>

<fit-checkbox-editor [control]="form.checkbox" [label]="'Checkbox'"
                     helperText="Do you need a checkbox editor?"
                     tooltipTitle="Title"
                     tooltip="If yes, please check box. If no, please leave box blank."
                     tooltipIcon="general-heart">
</fit-checkbox-editor>

<fit-radio-editor [control]="form.radio" [label]="'Radio'" [options]="radioOptions" helperText="Do you need a radio editor?" tooltip="put your colour notes here">
</fit-radio-editor>

<fit-currency-editor [control]="form.amount" [label]="'Amount'" helperText="Do you need a currency editor?" tooltip="put your currency notes here" tooltipIcon="general/gen017.svg">
</fit-currency-editor>

<fit-date-editor [control]="form.date" [label]="'Date'" helperText="Do you need a date editor?" tooltip="put your date notes here" tooltipIcon="general-heart">
</fit-date-editor>

<fit-number-editor [control]="form.number" [label]="'Number'" helperText="Do you need a number editor?" tooltip="put your number notes here">
</fit-number-editor>

<fit-phone-editor [control]="form.phoneNumber" [codeControl]="form.code" [label]="'Phone Number'" helperText="Do you need a phone editor?" tooltip="put your phone notes here">
</fit-phone-editor>

<fit-select-editor [control]="form.select" [label]="'Select'" [options]="selectOptions" helperText="Do you need a select editor?" tooltip="put your select notes here">
</fit-select-editor>

<fit-email-editor [control]="form.email" [label]="'Email'" helperText="Do you need a email editor?" tooltip="put your email notes here">
</fit-email-editor>

<fit-time-editor [control]="form.time" [label]="'Time'" helperText="Do you need a time editor?" tooltip="put your time notes here">
</fit-time-editor> 
