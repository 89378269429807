import { Component, Input } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Editor } from '@shared/editors/editor';
import { NameValueViewModel } from '@shared/viewmodels/name.value.viewmodel';

@Component({
  selector: 'fit-radio-editor',
  templateUrl: './radio.editor.html',
  styleUrls: ['./editor.scss']
})
export class RadioEditor extends Editor {

  @Input()
  groupId: string = uuidv4();

  @Input()
  options: Array<NameValueViewModel<any>>;

  @Input()
  isInline = true;
}
