import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fit-collapseheader',
  templateUrl: './collapseheader.component.html'
})
export class CollapseHeaderComponent implements OnInit {

  hover = false;

  @Input()
  header: string;

  @Input()
  tag: string;

  @Input()
  visible = true;

  @Output()
  visibleToggle: EventEmitter<[string, boolean]> = new EventEmitter();

  ngOnInit() {
  }

  onVisible() {
    this.visible = !this.visible;
    this.visibleToggle.emit([this.tag, this.visible]);
  } 
}
