<div class="wizard">
  <div class="wizard-nav">
    <div class="wizard-steps">
      <div class="wizard-step"
           *ngFor="let step of steps; index as i">
        <div class="wizard-wrapper"
             [ngClass]="{'active': activeStepIndex === i}">
          <div class="wizard-number">
            {{ i + 1 }}
          </div>
          <div class="wizard-label">
            <div class="wizard-title">
              {{ step }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wizard-body">
    <div class="container">
      <div class="row">
        <div class="col" [ngClass]="[containerClass]">
          <div class="wizard-container">
            <div class="wizard-content">
              <ng-content></ng-content>
            </div>
            <div class="wizard-footer">
              <button *ngIf="hasPreviousStep"
                      type="button"
                      class="btn btn-light-primary text-uppercase font-weight-bold btn-lg"
                      [disabled]="!canGoPreviousStep"
                      (click)="previousClick.next()">
                Previous
              </button>
              <div class="d-flex"></div>
              <button *ngIf="hasNextStep"
                      type="button"
                      class="btn btn-primary text-uppercase font-weight-bold ms-2 btn-lg"
                      [disabled]="!canGoNextStep"
                      (click)="nextClick.next()">
                Next
              </button>
              <button *ngIf="onLastStep"
                      type="button"
                      class="btn btn-success text-uppercase font-weight-bold ms-2 btn-lg"
                      [disabled]="!canFinish"
                      (click)="finishClick.next()">
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
