<div class="row" style="height:100%; align-items:center">
  <div class="col-sm-12 col-md-3"></div>
  <div class="col-sm-12 col-md-6" style="text-align:center;">
    <ngx-skeleton-loader [animation]="animation" [theme]="pieTheme"></ngx-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-1"></div>
  <div class="col-sm-12 col-md-2">
    <div class="row">
      <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
      <div class="mt-2">
        <ngx-skeleton-loader [animation]="animation" [theme]="textTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row">
      <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
      <div class="mt-2">
        <ngx-skeleton-loader [animation]="animation" [theme]="textTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row">
      <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
      <div class="mt-2">
        <ngx-skeleton-loader [animation]="animation" [theme]="textTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row">
      <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
      <div class="mt-2">
        <ngx-skeleton-loader [animation]="animation" [theme]="textTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row">
      <ngx-skeleton-loader [animation]="animation" appearance="circle" [theme]="dotTheme"></ngx-skeleton-loader>
      <div class="mt-2">
        <ngx-skeleton-loader [animation]="animation" [theme]="textTheme"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>

