import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fit-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  @Input()
  currentPage: number;

  @Input()
  totalItems: number;

  @Input()
  itemsPerPage: number;

  @Input()
  class = "";

  @Input()
  showPageOf = true;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter();

  constructor() {
    this.currentPage = this.currentPage || 1;
  }

  get lastPageNumber(): number {
    if (this.totalItems > 0) {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
    return 1;
  }

  get canGoPrevious(): boolean {
    return this.currentPage > 1;
  }

  get canGoNext(): boolean {
    return this.currentPage < this.lastPageNumber && this.totalItems > this.itemsPerPage;
  }

  canGoToPage(pageNumber: number): boolean {
    return pageNumber <= this.lastPageNumber;
  }

  goToPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.pageChange.emit(this.currentPage);
  }
  
  get isOnFirstPage(): boolean {
    return this.currentPage === 1;
  }

  get isOnLastPage(): boolean {
    return this.currentPage === this.lastPageNumber;
  }

  get buttonOneNumber(): number {
    if (this.isOnFirstPage || this.lastPageNumber === 2) {
      return 1;
    } else if (this.isOnLastPage) {
      return this.lastPageNumber - 2;
    } else {
      return this.currentPage - 1;
    }
  }

  get buttonTwoNumber(): number {
    if (this.isOnFirstPage || this.lastPageNumber === 2) {
      return 2;
    } else if (this.isOnLastPage) {
      return this.lastPageNumber - 1;
    } else {
      return this.currentPage;
    }
  }

  get buttonThreeNumber(): number {
    if (this.isOnFirstPage) {
      return 3;
    } else if (this.isOnLastPage) {
      return this.lastPageNumber;
    } else {
      return this.currentPage + 1;
    }
  }
}
