import { Component, Input } from '@angular/core';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-checkbox-editor',
  templateUrl: './checkbox.editor.html',
  styleUrls: ['./editor.scss']
})
export class CheckboxEditor extends InputEditor {
  @Input()
  labelClickable = false;

  @Input()
  labelAlignment: 'left' | 'right' = 'right';

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('checkbox', toggleTooltipsService);
  }

  toggleControl() {
    if (!this.isReadOnly) {
      this.control.setValue(!this.control.value);
    }
  }
}
