import { DocumentViewModel } from '@shared/viewmodels/document.viewmodel';

export class PdfDocumentViewModel extends DocumentViewModel {
  binaryData: string;

  constructor(document?: any) {
    super(document);
  }

    // Used to display document using pdf-viewer
    get path() {
      return this.blobLink ?? this.binaryData;
    }
}