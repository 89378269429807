import { Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { ModalConfig } from '@shared/modals/modal.config.interface';
import { Modal } from '@shared/modals/modal.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalHost: ViewContainerRef;

  constructor() { }


  setHost(host: ViewContainerRef) {
    this.modalHost = host;
  }

  getHost(): ViewContainerRef {
    return this.modalHost;
  }

  createInstance<T extends Modal>(type: Type<T>, config: ModalConfig) {
    if (this.modalHost) {
      this.clearHost();
      const componentRef = this.modalHost.createComponent(type);
      const instance = componentRef.instance as T;
      instance.configure(config);
      return instance;
    }
    return null;
  }

  createTemplateInstance<T>(template: TemplateRef<T>, context: T): void {
    if (this.modalHost) {
      this.clearHost();
      this.modalHost.createEmbeddedView(template, context);
    }
  }

  clearHost(): void {
    this.modalHost.clear();
  }
}
