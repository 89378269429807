import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringSplit' })
export class StringSplitPipe implements PipeTransform {

  transform(string: string): string {
    if (string && string.length > 1) {
      return string.match(/[A-Z][a-z]+/g).join(" ");
    }

    return string;
  }
}
