import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OperatorFunction } from 'rxjs';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

export type DropdownDirection = 'up' | 'down';
export type AlignmentDirection = 'left' | 'right';

@Component({
  selector: 'fit-typeahead-editor',
  templateUrl: './typeahead.editor.html',
  styleUrls: ['./typeahead.editor.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TypeaheadEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('text', toggleTooltipsService);
  }

  @Input()
  search: OperatorFunction<string, any[]>;

  @Input()
  editable: boolean = false;

  @Input() busyLoading = false;

  @Output() onClick = new EventEmitter<any>();

  @Output() beforeSelect = new EventEmitter<any>();

  @Output() onFocus = new EventEmitter<any>();

  //EXAMPLE:
  //search: OperatorFunction<string, readonly ViewModel[]> = (text$: Observable<string>) =>
  //  text$.pipe(
  //    distinctUntilChanged(),
  //    map(term => term.length < 2 ? []
  //      : this.projects.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  //  )
}
