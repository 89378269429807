import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { AbstractDataType } from '@shared/types/domain/abstract-data-type';

@Pipe({
  name: 'formControl',
})
export class FormControlPipe implements PipeTransform {
  transform(value: AbstractControl, adt: AbstractDataType): FormControl {

    switch (adt) {

      case AbstractDataType.boolean:
        return value as FormControl<boolean>
      case AbstractDataType.date:
      case AbstractDataType.dateTime:
        return value as FormControl<Date>
      case AbstractDataType.decimal:
      case AbstractDataType.float:
      case AbstractDataType.bigInteger:
      case AbstractDataType.integer:
      case AbstractDataType.short:
        return value as FormControl<number>
      case AbstractDataType.guid:
      case AbstractDataType.text:
      case AbstractDataType.timestamp:
        return value as FormControl<string>
    }
  }
}
