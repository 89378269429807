import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-currency-editor',
  templateUrl: './currency.editor.html',
  styleUrls: ['./editor.scss']
})
export class CurrencyEditor extends InputEditor {

  @Input()
  min = 0;

  @Input()
  max: number;

  @Input()
  currency = '£';

  amount: number;

  subscription: Subscription;

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('number', toggleTooltipsService);
  }
}
