import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { ConflictHttpInterceptor } from '@shared/interceptors/conflict.http.interceptor';
import { ServerErrorHttpInterceptor } from '@shared/interceptors/server-error.http.interceptor';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from '@auth/services/auth.service';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
        //@ts-ignore
        authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    SharedModule.forRoot(),
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ConflictHttpInterceptor, multi: true },

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
