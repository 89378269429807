<div class="row" style="height:100%">
  <div class="col-sm-12 col-md-1">
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
    <div class="row justify-content-end mt-5">
      <ngx-skeleton-loader [animation]="animation" [theme]="yTheme"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-2" style="align-self:flex-end; text-align: left; padding:0">
    <ngx-skeleton-loader [animation]="animation" [theme]="straightBarTheme"></ngx-skeleton-loader>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme" style="margin-left:-25%"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-2" style="align-self:flex-end; text-align: left; padding:0">
    <div style="padding-bottom:40%">
      <div style="margin-left:-1.5rem; transform: rotate(-45deg)">
        <ngx-skeleton-loader [animation]="animation" [theme]="angledBarTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme" style="margin-left:-25%"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-2" style="align-self:flex-end; text-align: left; padding:0">
    <div style="padding-bottom:100%">
      <ngx-skeleton-loader [animation]="animation" [theme]="straightBarTheme"></ngx-skeleton-loader>
    </div>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme" style="margin-left:-25%"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-2" style="align-self:flex-end; text-align: left; padding:0">
    <div style="padding-bottom:140%">
      <div style="margin-left:-1.5rem; transform: rotate(-45deg)">
        <ngx-skeleton-loader [animation]="animation" [theme]="angledBarTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme" style="margin-left:-25%"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-2" style="align-self:flex-end; text-align: left; padding:0">
    <div style="padding-bottom:160%">
      <div style="margin-left:-1.5rem; transform: rotate(45deg)">
        <ngx-skeleton-loader [animation]="animation" [theme]="angledBarTheme"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="mt-3">
      <ngx-skeleton-loader [animation]="animation" [theme]="xTheme" style="margin-left:-25%"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-1" style="align-self:flex-end; text-align: left; padding:0">
    <ngx-skeleton-loader [animation]="animation" [theme]="xTheme" style="margin-left:-50%"></ngx-skeleton-loader>
  </div>

</div>
