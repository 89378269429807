<div [style.width]="width ? width : null">
  <button type="button"
          [class]="isDisabled ? 'btn btn-outline ' + 'btn-' + size + ' fs-' + size : 'btn btn-outline ' + class + ' btn-' + size + ' fs-' + size "
          [ngClass]="{'btn font-weight-bold me-1 px-3': true}"
          [disabled]="isDisabled || busyLoading"
          [title]="title"
          (click)="onClick.next($event)">
    <div class="d-flex flex-row w-100 justify-content-center align-items-center">
      <i *ngIf="!busyLoading && icon" [class]="'ki-solid '+ 'fs-'+iconSize + ' ki-' + icon"></i>

      <div *ngIf="busyLoading" class="pb-1">
        <span  class="align-self-center spinner-border" [ngClass]="'spinner-border-'+ spinnerSize"></span>
      </div>
      
    </div>
  </button>
</div>
