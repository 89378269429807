import { ElementRef, HostListener, Directive } from '@angular/core';

@Directive({
  selector: '[no-decimal]'
})
export class NoDecimalDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
      if ((e.key === '.')) {
          e.preventDefault();
      }
  }
}
