import { Component } from '@angular/core';

@Component({
  selector: 'fit-pie-chart-skeleton',
  templateUrl: './pie-chart.skeleton.component.html'
})

export class PieChartSkeletonComponent {
  animation = 'pulse';
  pieTheme = { width: '75%', paddingBottom: '75%', borderRadius:'100%', margin: '0px' };
  dotTheme = { width: '10px', height: '10px', margin: '0px' };
  textTheme = { width: '40px', height: '5px', 'margin-left': '5px',};
}
