export class CountryViewModel {

  id: number;
  name: string;
  isoCode: string;
  isoCodeShort: string;
  isoNumber: number;
  countryCode: string;

  constructor(country?: any) {
    this.id = country?.id;
    this.name = country?.name;
    this.isoCode = country?.isoCode;
    this.isoCodeShort = country?.isoCodeShort;
    this.isoNumber = country?.isoNumber;
    this.countryCode = country?.countryCode;
  }

  public toString(): string {
    return this.name;
  }
}
