import { Component, Input } from '@angular/core';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-time-editor',
  templateUrl: './time.editor.html',
  styleUrls: ['./time.editor.css']
})
export class TimeEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('time', toggleTooltipsService);
  }

  @Input()
  width = '100%';
}
