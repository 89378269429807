import { Component } from '@angular/core';

@Component({
  selector: 'fit-bar-chart-skeleton',
  templateUrl: './bar-chart.skeleton.component.html'
})

export class BarChartSkeletonComponent {
  animation = 'pulse';
  yTheme = { width: '20px', height: '5px', margin: '0px' };
  barShortTheme = { width: '40px', height: '25px', margin: '0px' };
  barMedTheme = { width: '40px', height: '100px', margin: '0px' };
  barTallTheme = { width: '40px', height: '200px', margin: '0px' };
  xTheme = { width: '55px', height: '5px'};
}
