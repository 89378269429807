<div [ngClass]="dropdownClasses$ | async">
  <button class="btn btn-sm"
          [ngClass]="btnClass"
          [class.dropdown-toggle]="showToggleIcon"
          type="button"
          #button>
    <ng-content select="[icon]"></ng-content>
    {{ text }}
    <ng-content select="[toolbar]"></ng-content>
  </button>
  <div [class]="class"
       [ngClass]="menuClasses$ | async">
    <ng-content></ng-content>
  </div>
</div>
