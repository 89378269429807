import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Editor } from '@shared/editors/editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { Palette } from '@shared/theme/colour.palette';

@Component({
  selector: 'fit-input-editor',
  templateUrl: './input.editor.html',
  styleUrls: ['./editor.scss']
})

export class InputEditor extends Editor {

  @Output()
  keyEnter = new EventEmitter<KeyboardEvent>();

  @Input()
  height = '';

  @Input()
  type: string;

  @Input()
  autofocus = false;

  @Input()
  pattern: string;

  colorMap = Palette.getColourNameMap();

  constructor(@Inject(String) type: string = 'text',
    toggleTooltipsService: ToggleTooltipsService,
    decimalPipe?: DecimalPipe) {
    super(toggleTooltipsService, decimalPipe);
    this.type = type;
  }

  onKeyEnter(event: KeyboardEvent) {
    this.keyEnter.emit(event);
    event.preventDefault();
  }

}
