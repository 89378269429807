import { Component, Input } from '@angular/core';

@Component({
  selector: 'fit-cards-skeleton',
  templateUrl: './cards.skeleton.component.html',
  styleUrls: ['./cards.skeleton.component.scss']
})

export class CardsSkeletonComponent {
  animation = 'pulse';

  @Input() cardCount = 4;
  @Input() buttonCount = 3;
  @Input() iconTheme = { width: '30px', height: '30px', margin: '0px' };
  @Input() titleTheme = { width: '130px', height: '23px', margin: '0px' };
  @Input() subtitleTheme = { width: '90px', height: '17px', margin: '0px' };
  @Input() contentTheme = { width: '100%', height: '140px', margin: '0px' };
  @Input() buttonTheme = { width: '50px', height: '20px', margin: '0px 3.5px 0px 0px' };

}
