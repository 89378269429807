import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'fit-grid-button',
  templateUrl: './grid.button.component.html',
  styleUrls: ['./grid.button.component.css']
})
export class GridButtonComponent {

  @Input()
  busyLoading: boolean;

  @Input()
  icon: string;

  @Input()
  title: string = '';

  @Input()
  size = 'sm';

  @Input()
  spinnerSize = 'md';

  @Input()
  iconSize = '2x';

  @Input()
  class = 'btn-primary';

  @Output()
  onClick = new EventEmitter<MouseEvent>();

  @Input()
  isDisabled = false;

  @Input()
  width: string;

}
