export class NavigationPermissionsViewModel  {
  canViewEnvironments: boolean;
  canViewPipelines: boolean;
  canViewAnalytics: boolean;
  canViewManage: boolean;
  canViewReports: boolean;
  canViewSetup: boolean;
  canViewTeams: boolean;
  
  constructor(obj?: NavigationPermissionsViewModel) {
    if (obj) {
      this.canViewEnvironments = obj.canViewEnvironments;
      this.canViewPipelines = obj.canViewPipelines;
      this.canViewAnalytics = obj.canViewAnalytics;
      this.canViewManage = obj.canViewManage;
      this.canViewReports = obj.canViewReports;
      this.canViewSetup = obj.canViewSetup;
      this.canViewTeams = obj.canViewTeams;
    }
  }
}

