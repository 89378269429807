<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

      </div>
    </div>
  </div>

  <div [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-9'">
    <div class="input-group flex-nowrap ">
      <!--Phone Codes-->
      <div class="input-group-prepend pe-1 code">

        <div *ngIf="!isReadOnly" class="w-100 me-1">
          <div *ngIf="busyLoading" class="mt-5 text-center">
            <span class="spinner spinner-primary"></span>
          </div>

          <ng-container *ngIf="!busyLoading">
            <select class="form-select"
                    type="text"
                    [ngClass]="{'is-invalid': control.invalid && control.touched, 'text-gray-600': isReadOnly }"
                    [formControl]="codeControl"
                    [style.width]="codeWidth"
                    [style.padding.px]="'3px'">
              <option *ngFor="let country of countries"
                      [ngValue]="country.countryCode">
                {{country.isoCodeShort}} (+{{country.countryCode}})
            </select>
          </ng-container>
        </div>
        <div *ngIf="isReadOnly">
          <input class="form-control"
                 type="text"
                 [formControl]="codeControl"
                 [style.width]="codeWidth"
                 [ngClass]="{'text-gray-500': isReadOnly }"
                 [attr.placeholder]="placeHolder"
                 readonly />
        </div>

      </div>
      <!--Phone Number-->
      <div class="number">
        <input type="text"
               class="form-control phone-control w-100"
               [ngClass]="{'is-invalid': control.invalid && control.touched, 'text-gray-600': isReadOnly }"
               [formControl]="control"
               [style.width]="phoneWidth"
               [readOnly]="isReadOnly"
               [attr.placeholder]="placeHolder"
               only-number />
      </div>

    </div>

    <div *ngIf="control.invalid && control.touched && !isReadOnly"
         class="fv-plugins-message-container">
      <div class="fv-help-block"> {{getErrorMessage()}} </div>
    </div>

    <span *ngIf="helperText"
          class="form-text text-muted">{{helperText}}</span>
  </div>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

