import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[fitSlideOut]',
  exportAs: 'fitSlideOut'
})
export class SlideOutDirective implements AfterViewInit, OnDestroy {

  @Input('fitSlideOut')
  mediaQuery: string | string[];

  @Input('fitSlideOutPosition')
  position: 'left' | 'right' = 'left';

  @Input('fitSlideOutId')
  id: string = "edit";

  @Input('fitSlideOutDefaultStyle')
  defaultStyleOverides: { [key: string]: string; } = {};

  @Output()
  visibleChange = new EventEmitter<boolean>();

  private visible = false;
  private initialised = false;
  private destroyed = new Subject<boolean>();
  private defaultStyle: any;

  constructor(private elementRef: ElementRef<HTMLElement>,
    private mediaMatcher: MediaMatcher) { }

  ngAfterViewInit(): void {

    this.defaultStyle = {
      ...window.getComputedStyle(this.elementRef.nativeElement),
      ...this.defaultStyleOverides
    };

    this.setStyle(this.defaultStyle);

    fromEvent(window, 'resize').pipe(
      takeUntil(this.destroyed)
    ).subscribe(_ =>
      this.setStyle(this.defaultStyle)
    );

  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  toggleVisible(): void {
    this.setVisible(!this.visible);
  }

  show(): void {
    this.setVisible(true);
  }

  hide(): void {
    this.setVisible(false);
  }

  private setVisible(visible: boolean): void {
    this.visible = visible;
    this.setStyle(this.defaultStyle);
    this.visibleChange.emit(this.visible);
  }

  private setStyle(defaultStyle: any): void {

    const isMatch = (mediaQuery: string) =>
      this.mediaMatcher.matchMedia(mediaQuery).matches;

    const slideOutStyle: any = {
      position: 'fixed',
      top: '55px',
      zIndex: '20',
      height: 'calc(100vh - 55px)',
      borderRadius: '0',
      minWidth: defaultStyle.width,
      width: defaultStyle.width,
      transition: this.initialised ? 'transform 150ms ease' : 'transform 0ms ease',
      boxShadow: 'none'
    };

    this.initialised = true;

    if (this.position === 'left') {
      slideOutStyle.left = '0';
      slideOutStyle.transform = 'translateX(-100%)';
    } else {
      slideOutStyle.right = '0';
      slideOutStyle.transform = 'translateX(100%)';
    }

    const setStyle = (slideOutMode: boolean) => {

      let style = defaultStyle;

      if (slideOutMode) {

        style = slideOutStyle;

        // Is the side nav still active?
        if (isMatch('(min-width: 992px)')) {
          // TODO: Check if side nav open
          if (this.position === 'left') {
            style.left = '265px';
          }
          style.top = '119px';
          style.height = 'calc(100vh - 119px)';
        }

        // Smaller than the slide out width?
        if (isMatch(`(max-width: ${defaultStyle.width})`)) {
          style.width = '100%';
        }

        if (this.visible) {
          style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)';
          style.transform = 'translateX(0)';
          style.transition = 'transform 200ms ease';
        }
      }

      this.elementRef.nativeElement.style.position = style.position;
      this.elementRef.nativeElement.style.top = style.top;
      this.elementRef.nativeElement.style.zIndex = style.zIndex;
      this.elementRef.nativeElement.style.height = style.height;
      this.elementRef.nativeElement.style.borderRadius = style.borderRadius;
      this.elementRef.nativeElement.style.transform = style.transform;
      this.elementRef.nativeElement.style.maxWidth = style.maxWidth;
      this.elementRef.nativeElement.style.width = style.width;
      this.elementRef.nativeElement.style.transition = style.transition;
      this.elementRef.nativeElement.style.boxShadow = style.boxShadow;

      if (this.position === 'left') {
        this.elementRef.nativeElement.style.left = style.left;
      } else {
        this.elementRef.nativeElement.style.right = style.right;
      }
    };

    if (typeof this.mediaQuery === 'string') {
      setStyle(isMatch(this.mediaQuery as string));
    } else if (typeof this.mediaQuery === 'object' && Array.isArray(this.mediaQuery)) {
      setStyle(this.mediaQuery.some(mediaQuery => isMatch(mediaQuery)));
    }
  }
}
