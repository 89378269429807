import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResetPasswordCommand } from '@auth/components/reset-password/reset-password.command';
import { SearchCommand } from 'app/modules/search/commands/search.command';
import { AuthModel } from '@shared/models/auth.model';
import { NameValueViewModel } from '@shared/viewmodels/name.value.viewmodel';
import { UserViewModel } from '@shared/viewmodels/domain/user.viewmodel';
import { environment } from '../../../../../environments/environment';

const URL = `${environment.apiUrl}/auth-`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'x-functions-key': environment.apiKey
    });

    return this.http.post<AuthModel>(URL + `login`, {
      email,
      password,
    }, { headers: headers });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'x-functions-key': environment.apiKey
    });

    return this.http.post<boolean>(URL + `password-forgot`, {
      email,
    }, { headers: headers });
  }

  resetPassword(command: ResetPasswordCommand): Observable<boolean> {
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'x-functions-key': environment.apiKey
    });

    return this.http.post<boolean>(URL + `password-reset`, command, { headers: headers });
  }

  searchRegions(command: SearchCommand): Observable<NameValueViewModel<string>[]> {
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'x-functions-key': environment.apiKey,
    });

    return this.http.post<NameValueViewModel<string>[]>(`${URL}search-regions`, command, { headers: headers });
  }

  screenApplicant(): Observable<boolean> {
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'x-functions-key': environment.apiKey,
    });

    return this.http.post<boolean>(`${URL}screen-applicant`, { headers: headers });
  }

  getUserByToken(token: string): Observable<UserViewModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: token,
      'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
      'x-functions-key': environment.apiKey
    });

    return this.http.get<UserViewModel>(URL + `user-get`, {
      headers: httpHeaders,
    });
  }
}
