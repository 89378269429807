<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <span *ngIf="tooltip" class="svg-icon svg-icon-2 " style="cursor: pointer"
              [ngClass]="{'svg-icon-primary': !hovering, 'svg-icon-warning': hovering}"
              (mouseenter)="hovering=true" (mouseleave)="hovering=false"
              container="body" tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent"
              [inlineSVG]="'general/gen046.svg'">
        </span>


      </div>
    </div>
  </div>
  <div [ngClass]="fullWidth ?  'col-12' : 'col-9'" class="position-relative">
    <!--Icon-->
    <div class="input-group"
         [style.width]="width">
      <div class="input-group-text">
        <span class="svg-icon svg-icon-1" [inlineSVG]="'general/gen014.svg'">
        </span>
      </div>

      <!--allowInput=true
   altInput=false
  convertModelValue="true"-->
      <!--Editable-->
      <input *ngIf="!isReadOnly"
             #date
             type="text"
             class="form-control rounded-end"
             mwlFlatpickr
             [dateFormat]="dateFormat"
             [mode]="'range'"
             (flatpickrOpen)="onOpen($event)"
             (flatpickrClose)="onClose($event)"
             [ngClass]="{'is-invalid ': control.invalid && !control.pristine, 'text-gray-600': isReadOnly}"
             [formControl]="control"
             [minDate]="minDate ? minDate : null"
             [maxDate]="maxDate ? maxDate : null"
             [placeholder]="placeHolder ?? 'Choose a date'">
      <span class="position-absolute translate-middle-y top-50 end-0 me-3">
        <span *ngIf="!isReadOnly && control.value != null" class="svg-icon svg-icon-2x svg-icon-danger hover-scale cursor-pointer"
              [inlineSVG]="'general/gen040.svg'"
              (click)="$event.stopPropagation(); clearInput();" title="Clear Input"></span>
      </span>

      <!--[minDate]="minDate ? minDate : null"
      [maxDate]="maxDate ? maxDate : null"-->

      <!--Read Only-->
      <input *ngIf="isReadOnly"
             readonly
             type="text"
             [ngModel]="control.value | amDateFormat: 'yyyy-MM-DD'"
             placeholder="yyyy-mm-dd"
             class="form-control text-gray-600">
    </div>
    <div *ngIf="control.invalid && !control.pristine && !isReadOnly"
         class="fv-plugins-message-container">
      <div *ngIf="control.errors"
           class="fv-help-block">
        {{getErrorMessages()}}
      </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <div *ngIf="tooltipTitle" class="d-flex">
    <span class="svg-icon svg-icon-primary" *ngIf="tooltipIcon" [inlineSVG]="tooltipIcon">
    </span>
    <b class="pl-1 tooltip-title">{{tooltipTitle}}</b>
  </div>
  <div class="d-flex tooltip-text">
    {{tooltip}}
  </div>
</ng-template>
