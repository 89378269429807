import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList, Input, AfterViewInit } from "@angular/core";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TabBodyComponent } from '@shared/components/tab.body.component';
import { TabLabelComponent } from '@shared/components/tab.label.component';

@Component({
  selector: 'fit-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
 
  @ContentChildren(TabLabelComponent) labels: QueryList<TabLabelComponent>;
  @ContentChildren(TabBodyComponent) tabs: QueryList<TabBodyComponent>;
  @Output() notifyTabChange = new EventEmitter<string>();

  tabs$: Observable<TabBodyComponent[]>;

  @Input()
  activeTab: string;

  ngAfterContentInit(): void {
    this.tabs$ = this.tabs.changes
      .pipe(startWith(""))
      .pipe(map(() => this.tabs.toArray()));
  }

}
