
<div class="pdf-container">
  <pdf-viewer [src]="attachment?.path"
              (page-rendered)="busyLoading = false"
              [original-size]="false"
              style="display: block;">
  </pdf-viewer>
  <div *ngIf="busyLoading" class="mt-8 me-8 text-center">
    <span class="spinner spinner-primary spinner-lg"></span>
  </div>
</div>
<ng-content select="ngx-dropzone-label"></ng-content>
<ngx-dropzone-remove-badge *ngIf="removable" (click)="_remove($event)">
</ngx-dropzone-remove-badge>
