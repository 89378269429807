import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Editor } from '@shared/editors/editor';
import { ModalService } from '@shared/modals/modal.service';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { EditState } from '@shared/types/edit.state';
import { DocumentViewModel } from '@shared/viewmodels/document.viewmodel';
import { PdfDocumentViewModel } from '@shared/viewmodels/pdf.document.viewmodel';

@Component({
  selector: 'fit-pdf-editor',
  templateUrl: './pdf.editor.html',
  styleUrls: ['./editor.scss', './pdf.editor.scss']
})
export class PdfEditor extends Editor {

  EditState = EditState;

  @ViewChild('viewAttachmentTmpl')
  viewAttachmentModalTmpl: TemplateRef<any>;

  @Input()
  maxFileSize = 25000000;

  @Input()
  attachment: PdfDocumentViewModel;

  @Output()
  attachmentChange = new EventEmitter<DocumentViewModel>();

  @Input()
  label = 'Attachment';

  @Input()
  isReadOnly = false;

  @Input()
  showFullscreen = true;

  @Input()
  fullscreenHeader: string;

  constructor(private modalService: ModalService,
    private tooltipService: ToggleTooltipsService) {
    super(tooltipService);
  }

  get hasFiles(): boolean {
    return !!this.attachment;
  }

  get attachments(): PdfDocumentViewModel[] {
    return this.attachment?.hasDocument ? [this.attachment]: [];
  }

  isExistingDocument(document: DocumentViewModel): boolean {
    return !!document.blobName;
  }

  onSelect(event: { addedFiles: File[], rejectedFiles: File[]; }): void {
    this.attachment = fileToDocumentViewModel(event.addedFiles[0]);
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.attachment.binaryData = e.target.result;
    };
    reader.readAsArrayBuffer(this.attachment.file);
    this.attachmentChange.emit(this.attachment);
  }

  onRemove(): void {
    if (this.isExistingDocument(this.attachment)) {
      this.attachment.editState = EditState.deleted;
    } else {
      this.attachment = null;
    }
    this.attachmentChange.emit(this.attachment);
  }

  downloadAttachment() {
    let link = document.createElement('a');
    link.download = this.attachment.fileName;

    if (this.attachment.blobLink) {
      link.href = this.attachment.blobLink;
    } else {
      link.href = window.URL.createObjectURL(new Blob([this.attachment.file]));
    }
    link.click();
  }

  viewAttachment() {
    this.modalService.createTemplateInstance(this.viewAttachmentModalTmpl, {});
  }

  closeModal() {
    this.modalService.clearHost();
  }
}

const fileToDocumentViewModel = (file: File): PdfDocumentViewModel => {
  const viewModel = new PdfDocumentViewModel();
  viewModel.file = file;
  viewModel.fileName = file.name;
  viewModel.fileType = file.type;
  viewModel.filesizeKb = file.size / 1000;
  viewModel.editState = EditState.added;
  return viewModel;
};
