import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';
import { PdfDocumentViewModel } from '@shared/viewmodels/pdf.document.viewmodel';

@Component({
  selector: 'pdf-dropzone-preview',
  templateUrl: './pdf.dropzone.preview.component.html',
  styleUrls: ['./pdf.dropzone.preview.component.scss'],
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: PdfDropzonePreviewComponent
    }
  ]
})
export class PdfDropzonePreviewComponent extends NgxDropzonePreviewComponent implements OnChanges {

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
  
  busyLoading = true;

  @Input()
  attachment: PdfDocumentViewModel;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attachment']) {
      this.busyLoading = true;
    }
  }


}