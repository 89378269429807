<div class="image-container"
     [ngStyle]="loaderStyle">
     <img [attr.src]="src"
          class="img"
          (load)="onLoad()" />
     <div *ngIf="loading"
          class="image-loader"
          [@openClose]>
     </div>
</div>
