import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Editor } from '@shared/editors/editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-icon-editor',
  templateUrl: './icon.editor.html',
  styleUrls: ['./icon.editor.css']
})
export class IconSelectEditor extends Editor {

  @Input() width = '100%';

  @Input() selectionChangedHandler = (value: any) => { };

  @Output() selectionChanged = new EventEmitter<any>();

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  selectedIcon: string;
  controlValueChanges: Subscription;
  iconMap = new Map([['general/gen012.svg', 'HourGlass'], 
                    ['abstract/abs020.svg', 'Triforce'],
                    ['abstract/abs047.svg', 'Leaf'],
                    ['social/soc002.svg', 'Android'],
                    ['finance/fin007.svg', 'Calculator'],
                    ['coding/cod009.svg','Wrench'],
                    ['communication/com006.svg', 'User'],
                    ['general/gen030.svg', 'Heart'],
                    ['general/gen003.svg', 'Star'],
                    ['maps/map006.svg', 'Compass'],
                    ['medicine/med007.svg', 'Needle'],
                    ['abstract/abs008.svg', 'Hexagon'],
                    ['abstract/abs007.svg', 'Thundercloud']]);

  constructor(toggleTooltipsService: ToggleTooltipsService) {
  super(toggleTooltipsService);
  }

  ngOnInit() {
    this.controlValueChanges = this.control.valueChanges
      .subscribe((value) => {
        if (value !== null) {
          this.selectedIcon = value;
        }
      });
  }

  handleChange(value: any) {
    if (value != null) {
      this.control.setValue(value);
      if (this.selectionChangedHandler) {
        this.selectionChangedHandler(value);
      }
      this.selectionChanged.emit(value);
    }
  }

  onOpenChange(open: boolean) {
    if (this.isReadOnly) {
      this.dropdown.close();
      return;
    }
    if (!open) {
      this.control.markAsTouched();
      this.control.markAsDirty();
    }
  }

  ngOnDestroy() {
    if (this.controlValueChanges) {
      this.controlValueChanges.unsubscribe();
    }
  }
}
