<div *ngIf="totalItems > 0" class="d-flex flex-row">
  <ul class="pagination pagination-circle pagination-outline pagination-sm">
    <li [class]="class"
        [ngClass]="{'page-item previous': true, 'disabled': !canGoPrevious }">
      <span class="page-link cursor-pointer"
         [ngClass]="class"
         (click)="goToPage(currentPage - 1)">
        <i class="previous"></i>
      </span>
    </li>
    <li [class]="class"
        [ngClass]="{'page-item': true, 'active': currentPage === buttonOneNumber}">
      <a class="page-link cursor-pointer"
         [ngClass]="class"
         (click)="goToPage(buttonOneNumber)">
        {{ buttonOneNumber }}
      </a>
    </li>
    <li *ngIf="this.lastPageNumber > 1"
        [class]="class"
        [ngClass]="{'page-item': true, 'active': currentPage === buttonTwoNumber}">
      <a class="page-link cursor-pointer"
         [ngClass]="class"
         (click)="goToPage(buttonTwoNumber)">
        {{ buttonTwoNumber }}
      </a>
    </li>
    <li *ngIf="this.lastPageNumber > 2"
        [class]="class"
        [ngClass]="{'page-item': true, 'active': currentPage === buttonThreeNumber}">
      <a class="page-link cursor-pointer"
         [ngClass]="class"
         (click)="goToPage(buttonThreeNumber)">
        {{ buttonThreeNumber }}
      </a>
    </li>
    <li [class]="class"
        [ngClass]="{'page-item next': true, 'disabled': !canGoNext }">
      <span class="page-link cursor-pointer"
         [ngClass]="class"
         (click)="goToPage(currentPage + 1)">
        <i class="next"></i>
      </span>
    </li>
  </ul>

  <div *ngIf="showPageOf" class="d-flex ms-2 align-items-center fw-bold text-gray-700"
       [ngClass]="class">
    Page {{currentPage}} of {{lastPageNumber}}
  </div>
</div>
