<div class="form-group row">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

        <i *ngIf="showFullscreen && attachment?.hasDocument"
           class="ki-outline ki-arrow-diagonal text-primary cursor-pointer fs-3"
           (click)="viewAttachment()"></i>
      </div>
    </div>
  </div>
  <div class="col-12">
    <ngx-dropzone (change)="onSelect($event)"
                  class="small-dropzone"
                  [maxFileSize]="maxFileSize"
                  [multiple]="false"
                  accept="application/pdf"
                  [disableClick]="isReadOnly"
                  [expandable]="true">
      <ngx-dropzone-label>
        <i class="ki-outline ki-add-files text-primary fs-2x"></i>
        <span class="font-weight-bold text-muted">
          Drop file here or click to upload
        </span>
      </ngx-dropzone-label>
      <pdf-dropzone-preview ngProjectAs="ngx-dropzone-preview"
                            *ngFor="let a of attachments"
                            [file]="a.file"
                            [attachment]="a"
                            [removable]="true"
                            (removed)="onRemove()">
      </pdf-dropzone-preview>
    </ngx-dropzone>
    <small class="form-text text-muted">
      File max size is 25 MB
    </small>
  </div>
</div>

<ng-template #viewAttachmentTmpl>
  <div class="modal show">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row justify-content-space-between">
          <div class="modal-title d-flex flex-row align-items-center font-size-h3">
            {{fullscreenHeader?.length ? fullscreenHeader : label}}
            <button class="btn btn-icon ms-1" title="Download" (click)="downloadAttachment()">

              <i class="ki-outline ki-folder-down text-primary fs-2"></i>
            </button>
          </div>
          <fit-icon-button class="btn-icon btn-light-primary"
                           title="Close"
                           [icon]="'cross-circle'"
                           (click)="closeModal()">
          </fit-icon-button>
        </div>
        <div class="modal-body p-0 pb-1">
          <div class="pdf-container">
            <pdf-viewer [src]="attachment?.path"
                        [original-size]="false"
                        [fit-to-page]="true">
            </pdf-viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

