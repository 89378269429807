import { Component, Input } from '@angular/core';

@Component({
  selector: 'fit-progress-bar',
  templateUrl: './progress.bar.html'
})

export class ProgressBar {

  @Input()
  loop = false;

  @Input()
  busyLoading = false;
}
