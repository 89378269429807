<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

      </div>
    </div>
  </div>


  <div class="col-12" [ngClass]="{ 'col-md-9' : !fullWidth, 'mt-3': busyLoading && !fullWidth, 'mt-5': busyLoading && fullWidth}">
    <div *ngIf="busyLoading" [ngClass]="fullWidth ? 'mb-7' : '' ">
      <span class="spinner spinner-primary"></span>
    </div>

    <div *ngIf="!busyLoading">
      <ng-container *ngIf="useFormControl; else noFormControl">
        <form [formGroup]="getFormGroup(control)">
          <ngx-slider [options]="options" [formControlName]="getName(control)"></ngx-slider>
        </form>
      </ng-container>
      <ng-template #noFormControl>
        <div class="custom-slider">
          <ngx-slider [(value)]="minValue"
                      [(highValue)]="maxValue"
                      (valueChange)="minValueChange.emit(minValue)"
                      (highValueChange)="maxValueChange.emit(maxValue)"
                      (userChangeEnd)="emitValues()"
                      [options]="options">
          </ngx-slider>
        </div>
        <div class="d-flex flex-row mt-1" *ngIf="hasManualEditors">
          <div class="form-floating col-6 me-1">
            <input type="number" class="form-control" id="min" [(ngModel)]="minValue" (change)="emitValues()" [min]="min" [max]="max" placeholder="Min" />
            <label for="min">Min</label>
          </div>
          <div class="form-floating col-6">
            <input type="number" class="form-control" id="max" [(ngModel)]="maxValue" (change)="emitValues()" [min]="min" [max]="max" placeholder="Max" />
            <label for="max">Max</label>
          </div>
        </div>
      </ng-template>

      <span *ngIf="helperText"
            class="form-text text-muted">{{helperText}}</span>
      <div *ngIf="control.invalid && control.touched && !isReadOnly"
           class="fv-plugins-message-container">
        <div class="fv-help-block">
          {{getErrorMessage()}}
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

