import { Component, Input } from '@angular/core';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr';
import { FlatPickrOutputOptions } from 'angularx-flatpickr/lib/flatpickr.directive';
import { InputEditor } from '@shared/editors/input.editor';
import { ToggleTooltipsService } from '@shared/services/toggle.tooltips.service';
import { DateTime } from '@shared/types/date.time';


@Component({
  selector: 'fit-datetime-editor',
  templateUrl: './datetime.editor.html',
  styleUrls: ['./datetime.editor.scss']
})
export class DateTimeEditor extends InputEditor {
  options: FlatpickrDefaultsInterface = {
    allowInput: true,
    altInput: false,
    enableTime: true
  };

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('date', toggleTooltipsService);
  }

  @Input()
  width = '100%';

  @Input()
  min: string;

  @Input()
  max: string;

  @Input()
  defaultMargins = true;

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  @Input()
  showSymbol = true;

  @Input()
  class = true;

  ngOnInit() {
    this.options = {
      maxDate: this.maxDate ? this.maxDate : null,
      minDate: this.minDate ? this.minDate : null,
      enableTime: true,
      time24hr: true,
      dateFormat: "Y M d - H:i:S",
      allowInput: true,
      altInput: false,
    };
  }

  get displayDate(): string {
    if (this.control && this.control.value) {
      return new DateTime(this.control.value).toString();
    }

    return null;
  }

  get date(): Date {
    if (this.control && this.control.value) {
      return this.control.value;
    }
    return null;
  }

  set date(date: Date) {
    if (this.control) {
      this.control.setValue(date);
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  clearInput() {
    this.date = null;
  }

  onOpen(msg: FlatPickrOutputOptions) {
    msg.instance.input['readOnly'] = true;
  }

  onClose(msg: FlatPickrOutputOptions) {

    msg.instance.input['readOnly'] = false;
    msg.instance.input.blur();
  }
}
