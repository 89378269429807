import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SlideOutDirective } from '@shared/directives/slide-out/slide-out.directive';

@Directive({
  selector: '[fitSlideOutHideable]'
})
export class SlideOutHideableDirective implements AfterViewInit, OnDestroy {

  @Input('fitSlideOutHideable')
  mediaQuery: string | string[];

  @Input('fitSlideOutHideableSlideout')
  slideOut: SlideOutDirective;

  private destroyed = new Subject<boolean>();
  
  constructor(private elementRef: ElementRef<HTMLElement>,
    private mediaMatcher: MediaMatcher) {
  }

  ngAfterViewInit(): void {
    const defaultDisplay = window.getComputedStyle(this.elementRef.nativeElement).display;
    this.setVisibility(defaultDisplay);

    fromEvent(window, 'resize').pipe(
      takeUntil(this.destroyed)
    ).subscribe(_ => this.setVisibility(defaultDisplay));


    fromEvent(this.elementRef.nativeElement, 'click').pipe(
      takeUntil(this.destroyed)
    ).subscribe(_ => {
      if (this.slideOut) {
        this.slideOut.hide();
      }
    });
  }

  ngOnDestroy() {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private setVisibility(displayType: string) {

    const isMatch = (mediaQuery: string) =>
      this.mediaMatcher.matchMedia(mediaQuery).matches;

    const setDisplay = (match: boolean) => {
      if (match) {
        this.elementRef.nativeElement.style.display = 'none';
      } else {
        this.elementRef.nativeElement.style.display = displayType;
      }
    };

    if (typeof this.mediaQuery === 'string') {
      setDisplay(isMatch(this.mediaQuery as string));
    } else if (typeof this.mediaQuery === 'object' && Array.isArray(this.mediaQuery)) {
      setDisplay(this.mediaQuery.some(mediaQuery => isMatch(mediaQuery)));
    }
  }

}
