import { Component } from "@angular/core";

@Component({
  selector: "fit-tab-body",
  template: `<div class="h-100"><ng-content></ng-content><div>`
})
export class TabBodyComponent {


}
