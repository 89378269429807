<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative"
           [ngClass]="{'text-gray-500': isReadOnly}">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <i *ngIf="tooltip" class="ki-outline ki-question-2 fs-3 align-self-end" style="cursor: pointer"
           [ngClass]="{'text-primary': !hovering, 'text-warning': hovering}"
           (mouseenter)="hovering=true" (mouseleave)="hovering=false" container="body"
           tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
        </i>

      </div>
    </div>
  </div>


  <div class="col-12" [ngClass]="{ 'col-md-9' : !fullWidth, 'mt-3': busyLoading && !fullWidth, 'mt-5': busyLoading && fullWidth}">
    <!--Editable-->
    <div *ngIf="busyLoading" [ngClass]="fullWidth ? 'mb-3' : '' ">
      <span class="ms-4 spinner-border text-primary"></span>
    </div>

    <div *ngIf="!busyLoading && options && options.length > 0">
      <div class="position-relative">
        <select class="form-select"
                type="text"
                [ngClass]="{'is-invalid': control.invalid && control.touched, 'text-gray-600': isReadOnly}"
                [formControl]="control"
                [style.width]="width"
                (ngModelChange)="handleChange($event)"
                #input>
          <option *ngIf="addNullOption" [ngValue]="null"></option>
          <option *ngFor="let option of options"
                  [ngValue]="value ? option[value] : option"
                  [disabled]="isReadOnly ? true : null ">
            {{display ? option[display] : option}}
          </option>

        </select>
        <div class="position-absolute translate-middle-y top-50 end-0 me-10">
          <div *ngIf="canClear" class="d-flex">
            <i *ngIf="!busyLoading && !isReadOnly && control.value != null"
               title="Clear Input"
               class="ki-outline ki-cross-circle text-gray-600 cursor-pointer fs-2 mt-auto"
               (click)="$event.stopPropagation(); control.setValue(null)"></i>
          </div>
        </div>
      </div>

      <span *ngIf="helperText"
            class="form-text text-muted">{{helperText}}</span>
      <div *ngIf="control.invalid && control.touched && !isReadOnly"
           class="fv-plugins-message-container">
        <div class="fv-help-block">
          {{getErrorMessage()}}
        </div>
      </div>
    </div>

    <!-- No Items -->
    <div *ngIf="!busyLoading && (!options || options?.length < 1)">
      <input class="form-control text-muted"
             type="text"
             [style.width]="width"
             [attr.placeholder]="placeHolder"
             value="No Options"
             readonly disabled />
      <span *ngIf="helperText"
            class="form-text text-muted">
        {{helperText}}
      </span>
    </div>

  </div>
</div>


<ng-template #tipContent>
  <div class="d-flex flex-column">
    <div *ngIf="tooltipTitle" class="d-flex flex-row align-self-center">
      <i *ngIf="tooltipIcon" class="ki-outline ki-{{tooltipIcon}} text-primary fs-1"></i>
      <b class="pl-1 fs-6 fw-bold">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex">{{tooltip}}</div>
  </div>
</ng-template>

