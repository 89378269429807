import { Options, TranslateFunction } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Editor } from '@shared/editors/editor';

@Component({
  selector: 'fit-range-editor',
  templateUrl: './range.editor.html',
  styleUrls: ['./editor.scss', './range.editor.scss']
})
export class RangeEditor extends Editor {

  @Input() useFormControl = true;

  @Input() maxValue: number;
  @Output() maxValueChange = new EventEmitter<number>();

  @Input() minValue: number;
  @Output() minValueChange = new EventEmitter<number>();

  @Output() valuesChanged = new EventEmitter<{ min: number, max: number }>();

  @Input() hasSlider = true;

  @Input() hasManualEditors = false;

  //Cant use floor(0) with log scale
  @Input() useLogScale = false;

  @Input() noSwitching = false;

  @Input() showTicks = false;

  @Input() showSelectionBar = true;

  @Input() showTickValues = false;

  @Input() draggableRange = true;

  @Input() draggableRangeOnly = false;

  @Input() pushRange = false;

  @Input() min: number = 1;

  @Input() minLimit: number;

  @Input() minRange: number = 1;

  @Input() max: number = 100;

  @Input() maxLimit: number;

  @Input() maxRange: number;

  @Input() step: number = 1;

  @Input() tickStep = 1;

  @Input() translate: TranslateFunction = (value: number): string => {
    if (value) {
      return '' + value;
    }
    return '';
  };

  @Input() width = '100%';

  @Input() defaultMargins = true;

  @Input() busyLoading = false;

  sliderTouched = false;

  options: Options = { floor: this.min, ceil: this.max };

  ngOnChanges() {

    this.options = Object.assign({}, this.options, {
      readOnly: this.isReadOnly,
      floor: this.min,
      ceil: this.max,
      step: this.step,
      minLimit: this.minLimit,
      maxLimit: this.maxLimit,
      minRange: this.minRange,
      maxRange: this.maxRange,
      draggableRange: this.draggableRange,
      draggableRangeOnly: this.draggableRangeOnly,
      showSelectionBar: this.showSelectionBar,
      showTicks: this.showTicks,
      showTicksValues: this.showTickValues,
      tickStep: this.tickStep,
      noSwitching: this.noSwitching,
      translate: this.translate,
      logScale: this.useLogScale,
    });
  }

  getFormGroup(control: AbstractControl) {
    return <FormGroup>control?.parent;
  }

  public getName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;

    if (!group) {
      return null;
    }

    let name: string;

    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);

      if (childControl !== control) {
        return;
      }

      name = key;
    });

    return name;
  }

  emitValues() {
    this.sliderTouched = true;
    this.valuesChanged.emit({ min: this.minValue, max: this.maxValue });
  }
}
