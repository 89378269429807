import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPasswordCommand } from '@auth/components/reset-password/reset-password.command';
import { Ability, createMongoAbility } from '@casl/ability';
import { environment } from '@environments/environment';
import { SearchCommand } from 'app/modules/search/commands/search.command';
import { AuthModel } from '@shared/models/auth.model';
import { EnvironmentType } from '@shared/types/domain/enviroment.type';
import { SystemPermission } from '@shared/types/domain/system-permission';
import { UserViewModel } from '@shared/viewmodels/domain/user.viewmodel';
import { NameValueViewModel } from '@shared/viewmodels/name.value.viewmodel';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AuthHTTPService } from './auth-http';


export type UserType = UserViewModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentEnviroment$: Observable<EnvironmentType>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentEnviromentSubject: BehaviorSubject<EnvironmentType>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  get currentEnviromentValue(): EnvironmentType {
    return this.currentEnviromentSubject.value;
  }

  get isAdministrator(): boolean {
    return this.currentUserSubject.value?.isAdministrator;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  set currentEnviromentValue(enviroment: EnvironmentType) {
    this.currentEnviromentSubject.next(enviroment);
  }

  able(action: string, subject: string): boolean {
    return this.ability.can(action, subject);
  };

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private ability: Ability) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentEnviromentSubject = new BehaviorSubject<EnvironmentType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentEnviroment$ = this.currentEnviromentSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('login error', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    if (localStorage.getItem(this.authLocalStorageToken)) {
      localStorage.removeItem(this.authLocalStorageToken);
      this.router.navigate(['/auth/login'])
    } else {
      this.router.navigate(['/auth/information'], {
        queryParams: {},
      });
    }
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          let properUser = new UserViewModel(user);
          this.ability.update(createMongoAbility(properUser.permissions).rules);
          this.currentUserSubject.next(properUser);
        } else {
          this.ability.update([]);
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resetPassword(command: ResetPasswordCommand): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(command)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  searchRegions(command: SearchCommand): Observable<NameValueViewModel<string>[]> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .searchRegions(command)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  screenApplicant(): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .screenApplicant()
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  get authToken() {
    return this.getAuthFromLocalStorage().authToken;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
